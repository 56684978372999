import Cookie from 'js-cookie'


export const setCookie = (name: string, data: string) => {
  Cookie.set(name, data, { expires: 365 })
}

export const removeCookie=(name:string)=>{
    Cookie.remove(name)
}

export const getCookie=(name:string)=>{
   return Cookie.get(name)
}
