import { useTranslation } from 'next-i18next';
import { FormProps } from '../../../../../interface/Element';
import SelectInput from '../../../../shared/form/select/SelectInput';

function ColorSelect({ control, errors, name = "" }: FormProps) {
    const { t } = useTranslation()



    return (
        <>
            <SelectInput
                options={[
                    { label: t("Common.chooseOption"), value: "" },
                    { label: t("Common.gold"), value: "#FFF323" },
                    { label: t("Common.silver"), value: "#AAAAAA" },
                    { label: t("Common.roseGold"), value: "#B76E79" },
                    { label: t("Common.green"), value: "#3c875e" },
                    { label: t("Common.pink"), value: "#d1979b" },
                    { label: t("Common.black"), value: "#090909" },
                    { label: t("Common.white"), value: "#ffffff" },
                    { label: t("Common.orange"), value: "#d74b1b" },
                    { label: t("Common.mixColor"), value: "#bfbbb8" },
                    { label: t("Common.blue"), value: "#a1d3e2" },
                    { label: t("Common.lilac"), value: "#e1ccff" },
                    { label: t("Common.cream"), value: "#edcd9a" },
                    { label: t("Common.Champagne"), value: "#A37B73FF" },
                    { label: t("Common.purple"), value: "#9932cc" },
                    { label: t("Common.magenta"), value: "#E0115F" },
                    { label: t('Common.elephant'), value: '#A69C9B' },
                    { label: t('Common.yellow'), value: '#ffff00' }

                ]}
                control={control}
                errors={errors}
                keyValue={name}
                label={t("Txt.color")}
                name={name}
                changeF={() => { }}

            />

        </>
    )
}

export default ColorSelect