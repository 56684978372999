import { FC, useEffect } from "react"
import { ICommentUser } from "../../../../interface/EntityProduct"
import GrayLine from "../../../shared/Html/GrayLine"
import TitleValue from "../../../shared/Html/TitleValue"
import { useTranslation } from "next-i18next"
import SwitchComponent from "../../../shared/form/switch"
import { useProductCommentEditMutation, useProductCommentEditUserMutation } from "../../../../data/services/Product"
import { ToastSuccess } from "../../../../utility/ToastFunction"

interface IProps {
    id: number,
    listComments?: ICommentUser[]
}

const ListCommentsUsers: FC<IProps> = ({ id, listComments }) => {

    const { t } = useTranslation()
    // const [edit, result] = useProductCommentEditMutation()
    const [edit, result] = useProductCommentEditUserMutation()


    useEffect(()=>{
        if(result.data?.success == true){
            ToastSuccess(t("Common.successAlarm"))
        }
    },[result])

    return (
        <div className="FormModal py-4 w-full">
            <div className='flexCol gap-10 text-lg '>
                {listComments?.map((comment: ICommentUser) => (
                    <div className="w-full flex flex-col">
                        <div className="w-full flex flex-row">
                            {/* <div key={comment.id} className="flexRow gap-1 md:w-[75%] md:mx-auto"> */}
                            <div key={comment.id} className="flex flex-row justify-center md:w-[85%] md:mx-auto items-center">
                                <TitleValue title={t("Common.comment")} value={comment.text} className="justify-center flex flex-row pr-2" />
                            </div>
                            <div key={comment.id} className="flex justify-evenly gap-1 items-center md:w-[25%] md:mx-auto">
                                <p className="text-nowrap">{t("Common.Do you agree with this comment")}</p>
                                <SwitchComponent checked={comment.confirm === 1} handleChange={(data: any) => { edit({ confirm: data ? 1 : 0, id: comment.id }) }} />
                            </div>

                        </div>
                        <GrayLine />
                    </div>

                ))}
            </div>
        </div>
    )
}
export default ListCommentsUsers