import React, { useEffect, useState } from 'react'
import ModalFull from '../../shared/modal/ModalFull'
import { useProductDetailQuery } from '../../../data/services/Product'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Loader } from "../../shared/Loader"
import { useTranslation } from "next-i18next"
import EyeIcon from "../../shared/Icon/Eye"
import TabBtn from "../../shared/Html/TabBtn"
import TabBar from "../../../layout/tabBar"
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../data/store'
import { emptyUserFormData, setUserFormMode } from '../../../data/slice/user/UserForm'
import AttachmentIcon from '../../shared/Icon/Attachment'
import InfoUser from './info'
import AttachmentUser from './attachment'
import WalletIcon from '../../shared/Icon/Wallet'
import WalletUser from './wallet'
import EditUser from '../form/edit/EditUser'
import AddressesList from './addresses';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import orderSettingWholesale from './orderSettingWholesale/index';
import OrderSettingWholesale from './orderSettingWholesale/index';

export default function DetailUser() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const Data = useSelector((state: RootState) => state.UserForm);
    const [tab, setTab] = useState("info");
    const mode = useSelector((state: RootState) => state.UserForm.mode)


    return (
        <>
            <ModalFull ChangeOpen={(open: boolean) => {
                dispatch(setUserFormMode("none"));
                dispatch(emptyUserFormData());
            }} Open={Data.mode === "view"}
                title={Data.data?.first_name + " " + Data.data?.last_name}>

                {Data.data && <>

                    <TabBar>
                        <div onClick={() => setTab("info")} className="w-full">
                            <TabBtn active={tab === "info"} icon={<EyeIcon size="20px" />} txt={t("Txt.info")} />
                        </div>
                        <div onClick={() => setTab("attachment")} className="w-full" >
                            <TabBtn active={tab === "attachment"} icon={<AttachmentIcon size="20px" />} txt={t("Txt.Documents")} />
                        </div>
                        <div onClick={() => setTab("wallet")} className="w-full" >
                            <TabBtn active={tab === "wallet"} icon={<WalletIcon size="20px" />} txt={t("Txt.wallet")} />
                        </div>
                        <div onClick={() => setTab("address")} className="w-full" >
                            <TabBtn active={tab === "address"} icon={<LocationOnIcon />} txt={t("Txt.addresses")} />
                        </div>
                        {Data.data.type == "wholesale" && <div onClick={() => setTab("orderSetting")} className="w-full" >
                            <TabBtn active={tab === "orderSetting"} icon={<AssuredWorkloadIcon />} txt={t("Txt.orderSetting")} />
                        </div>}
                    </TabBar>

                    {tab === "info" && <InfoUser id={Data.data?.id} />}
                    {tab === "attachment" && <AttachmentUser id={Data.data?.id} />}
                    {tab === "wallet" && <WalletUser id={Data.data?.id} />}
                    {tab === "address" && <AddressesList id={Data.data?.id} />}
                    {tab === "orderSetting" && <OrderSettingWholesale user_id={Data.data.id} minFirsOrderPrice={Data?.data?.minFirsOrderPrice?.min_first_order_price ? Data?.data?.minFirsOrderPrice.min_first_order_price : "0"} />}
                </>
                }



            </ModalFull>
            {mode === "edit" && <EditUser />}
        </>
    )
}

