import { useEffect, useState } from 'react'

import { GetItemLocal } from '../utility/FunctionStorage';
import { GetToken } from '../utility/TokenFunction';
import { getCookie } from '../utility/cookieFunctions';

const useCheckAuth = () => {
  const [auth, setAuth] = useState<boolean|undefined>(undefined);
  useEffect(() => {
    
    // let token=GetItemLocal("userA");
    let token=getCookie("token");
    // let token=GetToken();
     if (token === '' || token===undefined ||token===null)
      {
        setAuth(false)
      }else{
        setAuth(true)
      }

  }, [])
  return auth
}

export default useCheckAuth