import { useEffect, useState } from 'react'
import { Loader } from '../../shared/Loader'
import { useTranslation } from 'next-i18next'
import { useUserQuery } from '../../../data/services/User'
import LabelTxt from '../../shared/Html/LabelTxt'
import Confirm from '../actions/confirm'
import Block from '../actions/block'
import DetailUser from '../view'
import FilterUsers from '../form/filter'
import ListDataTable from './../../shared/listData/index'

// redux
import { useDispatch } from 'react-redux'
import {
  setUserFormData,
  setUserFormMode
} from '../../../data/slice/user/UserForm'

// icons
import SettingIcon from '../../shared/Icon/Setting'
import EyeIcon from '../../shared/Icon/Eye'

const ListData = () => {
  const { t } = useTranslation()
  const [page, setPage] = useState<number>(1)
  const [filter, setFilter] = useState<any>({
    page,
    type: undefined,
    searchKey: undefined,
    confirm: undefined,
    block: undefined
  })
  const dispatch = useDispatch()
  const { data, isLoading, refetch } = useUserQuery(filter)

  // header table
  const Headers = [
    { value: <b>#</b>, width: 'w-[150px]' },
    { value: <b>{t('Common.name')}</b> },
    { value: <b>{t('Common.mobile')}</b> },
    { value: <b>{t('Txt.statusTitle')}</b> },
    { value: <b>{t('Txt.confirm')}</b> },
    { value: <b>{t('Txt.confirm')}</b> },
    { value: <b>{t('Txt.block')}</b> },
    { value: <b>{t('Txt.actions')}</b>, width: 'w-[400px]' }
  ]

  const Rows = data?.data?.data.map(item => {
    return {
      row: [
        { value: <div className='ms-1'>{item.id}</div>, width: 'w-[150px]' },
        { value: <div>{item.first_name + ' ' + item.last_name}</div> },
        { value: <div>{item.mobile}</div> },
        {
          value: (
            <div>
              <LabelTxt
                txt={t('Txt.type_' + item.type)}
                bg={
                  item.type === 'wholesale'
                    ? 'bg-[--brand]'
                    : 'bg-[--secondary]'
                }
              />
            </div>
          )
        },
        {
          value: (
            <div>
              <LabelTxt
                bg={item.confirm ? 'bg-[--success]' : 'bg-[--warning]'}
                txt={t('Txt.confirm_' + item.confirm)}
              />
            </div>
          )
        },
        {
          value: (
            <div>
              {' '}
              <Confirm confirm={item.confirm ?? 0} id={item.id} />
            </div>
          )
        },
        {
          value: (
            <div>
              {' '}
              <Block block={item.block ?? 0} id={item.id} />
            </div>
          )
        },
        {
          value: (
            <div className='flexRow items-center'>
              <div
                className='cursor-pointer'
                onClick={() => {
                  dispatch(setUserFormMode('view'))
                  dispatch(setUserFormData(item))
                }}
              >
                <EyeIcon fill='var(--secondary)' size='30px' />
              </div>
              <div
                className='cursor-pointer'
                onClick={() => {
                  // handleOpenEditModal(item.id)
                  dispatch(setUserFormData(item))
                  dispatch(setUserFormMode('edit'))
                }}
              >
                <SettingIcon fill='var(--secondary)' size='30px' />
              </div>
            </div>
          ),
          width: 'w-[400px]'
        }
      ]
    }
  })

  const handleChangePageNumber = async (pageNumber: number) => {
    await setPage(pageNumber)
    setFilter({ ...filter, page: pageNumber })
  }

  useEffect(() => {
    refetch()
  }, [page])

  return (
    <>
      <FilterUsers
        page={page}
        filter={filter}
        setFilter={setFilter}
        getData={() => refetch()}
      />
      <div className='  py-4'>
        {isLoading && (
          <div className='flexRow justify-center w-full'>
            <Loader />
          </div>
        )}
        {!isLoading && (
          <ListDataTable
            header={Headers}
            setPage={(e: number) => {
              handleChangePageNumber(e)
            }
            }
            handleChangePage={(params1: any, params2: number) => {
              let newPage = params2 + 1
              handleChangePageNumber(newPage)
            }
            }
            rows={Rows ?? []}
            currentPage={+(data?.data.current_page ?? 1) - 1}
            rowsPerPage={+(data?.data.per_page ?? 0)}
            total={+(data?.data.total ?? 0)}
            onRowsPerPageChange={(params1: any, params2: any) =>
              console.log(params1, params2)
            }
          />
        )}
      </div>
      <DetailUser />
    </>
  )
}

export default ListData
