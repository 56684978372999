import { Outlet, useNavigate } from 'react-router-dom'

import Layout from '..'
import useCheckAuth from '../../hook/CheckAuth'
import  { useEffect } from 'react'
import { UrlS } from '../../config/Urls'
import { removeCookie } from '../../utility/cookieFunctions'
import { RemoveItemLocal } from '../../utility/FunctionStorage'

const LayoutWithAuth = () => {

    const navigate=useNavigate()
    const Auth=useCheckAuth()

    useEffect(()=>{
        if(!Auth && Auth!==undefined){
            removeCookie("token")
            RemoveItemLocal("userA")
            navigate(UrlS.login.main)
        }
      },[Auth, navigate])
    return (
        <>
            {Auth && Auth!==undefined&&<Layout><Outlet /></Layout>}
        </> 
    )
}

export default LayoutWithAuth