import FilterAltIcon from '@mui/icons-material/FilterAlt'

import ButtonBrand from '../../shared/button/ButtonBrand'
import { useTranslation } from 'next-i18next'
import { useDispatch } from 'react-redux'
import { setOrderFormMode } from '../../../data/slice/order/OrderForm'

function Header () {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  return (
    <section className='w-ful flex justify-between items-center'>
      <h1 className='text-2xl'>{t('Menu.orders')}</h1>
      <div className='flex'>
        <ButtonBrand
          className='text-nowrap px-4 w-[100px]'
          click={() => {
            dispatch(setOrderFormMode('filter'))
          }}
        >
          <div className='flex items-center gap-2'>
            <span>
              <FilterAltIcon fill='var(--light)' />
            </span>
            <b> {t('Txt.filter')} </b>
          </div>
        </ButtonBrand>
      </div>
    </section>
  )
}

export default Header
