
import { useSelector } from 'react-redux'
import { RootState } from '../../../data/store'
import AddDiscount from './actions/add'
import EditDiscount from './actions/edit'
import FliterDiscount from './actions/filter'
import DetailsDiscount from './actions/detail'


export default function FormFaq() {

  const mode = useSelector((state: RootState) => state.DiscountForm.mode)

  return (
    <>
      <div className='w-full h-full bg-[--light] p-2 rounded-[--radius]'>


        {/* {mode === "delete" && <DeleteBannerComponent />} */}
        {mode === "add" && <AddDiscount />}
        {mode === "edit" && <EditDiscount />}
        {mode === "detail" && <DetailsDiscount />}
      </div>
    </>
  )
}
