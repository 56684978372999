import { useTranslation } from "next-i18next"
import { Fragment, useEffect, useState } from "react"
import { useGetActivityQuery } from "../../../data/services/Activity"
import { Loader } from '../../shared/Loader'
import ListDataTable from './../../shared/listData/index'
import EyeIcon from "../../shared/Icon/Eye"
import { showPersianDate } from "../../../utility/Function"
import { Tooltip } from "@mui/material"
import DetailActivityModal from "../../shared/dialog/DetailActivityModal"
import { ActivityDataType } from "../../../interface/EntityActivity"
import CustomSelect from "../../shared/form/input/CustomSelect"
import SelectWithoutForm from "../../shared/form/input/SelectWithoutForm"


const ListActivity = () => {
    const { t } = useTranslation()
    const [page, setPage] = useState<number>(1)
    const [showModal, setShowModal] = useState<boolean>(false)
    const [activity, setActivity] = useState<ActivityDataType>()
    const [activitiStatus, setActivitiStatus] = useState('all')

    const { data, isLoading, refetch } = useGetActivityQuery({ page ,type:activitiStatus === "all" ? undefined : activitiStatus})

    const handleChangePageNumber = async (pageNumber: number) => {
        await setPage(pageNumber)
        // setFilter({ ...filter, page: pageNumber })
    }

    const optionUserBlock = [
        { label: t('Common.all'), value: 'all' },
        { label: t('Common.insert'), value: 'insert' },
        { label: t('Common.update'), value: 'update' },
        { label: t('Common.delete'), value: 'delete' }
      ]
    const Headers = [
        { value: <b>#</b>, width: 'w-[150px]' },
        { value: <b>{t('Common.user_id')}</b> },
        { value: <b>{t('Common.admin_id')}</b> },
        { value: <b>{t('Common.type')}</b> },
        { value: <b>{t('Common.created_at')}</b> },
        { value: <b>{t('Txt.actions')}</b>, width: 'w-[380px]' }
    ]

    const Rows = data?.data?.data.map(item => {
        return {
            row: [
                { value: <div className='ms-1 text-center'>{item.id}</div>, width: 'w-[150px]' },
                { value: <div>{item.user_id == null ? "jfaksld" : item.user_id}</div> },
                { value: <div>{item.admin_id}</div> },
                {
                    value: (
                        <div>
                            <div className={` ${item.type === "delete" ? "bg-red-700" : item.type == "insert" ? "bg-green-700" : "bg-yellow-500"}  text-white py-1 px-2 rounded-[--radius] `}>
                                <span>{t(`Common.${item.type}`)}</span>
                            </div>
                        </div>
                    )
                },
                { value: <div>{showPersianDate(item.created_at)}</div> },
                {
                    value: (
                        <div className='flexRow items-center'>
                            <Tooltip title={t("Common.showDescriptonActivity")}>
                                <div
                                    className='cursor-pointer'
                                    onClick={() => {
                                        setActivity(item)
                                        setShowModal(true)
                                    }}
                                >
                                    <EyeIcon fill='var(--secondary)' size='30px' />
                                </div>
                            </Tooltip>
                        </div>
                    ),
                    width: 'w-[380px]'
                }
            ]
        }
    })

    useEffect(() => {
        refetch()
    }, [page,activitiStatus])


    return (
        <Fragment>
            <section className='w-full flex justify-between'>
                <h1 className='text-2xl'>{t('Menu.activity')}</h1>
                <SelectWithoutForm
            classContainer='!w-[200px]'
            label={t('Common.selectStateActivity')}
            options={optionUserBlock}
            placeholder={t('Common.selectStateActivity')}
            getValue={(e: { label: string; value: string }) => {
              setActivitiStatus(e.value)
            }}
          />
            </section>
            <div className='  py-4'>
                {isLoading && (
                    <div className='flexRow justify-center w-full'>
                        <Loader />
                    </div>
                )}
                {!isLoading && (
                    <ListDataTable
                        header={Headers}
                        setPage={(e: number) => {
                            handleChangePageNumber(e)
                        }
                        }
                        handleChangePage={(params1: any, params2: number) => {
                            let newPage = params2 + 1
                            handleChangePageNumber(newPage)
                        }
                        }
                        rows={Rows ?? []}
                        currentPage={+(data?.data.current_page ?? 1) - 1}
                        rowsPerPage={+(data?.data.per_page ?? 0)}
                        total={+(data?.data.total ?? 0)}
                        onRowsPerPageChange={(params1: any, params2: any) =>
                            console.log(params1, params2)
                        }
                    />
                )}
            </div>
            <DetailActivityModal open={showModal} CloseModal={() => setShowModal(false)} activity={activity} />
        </Fragment>
    )
}
export default ListActivity