import { ProductData, ProductDetailData } from '../../../../interface/EntityProduct'
import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'

import ButtonBrand from '../../../shared/button/ButtonBrand'
import FileInput from '../../../shared/form/upload/FileInput'
import Gallery from '../../detail/info/Gallery'
import { useAddNewGalleryMutation, useEditGalleryMutation, useProductEditPostMutation } from '../../../../data/services/Product'
import { useTranslation } from 'next-i18next'
import InputTextWithoutForm from '../../../shared/form/input/InputTextWithoutForm'
import { ToastError, ToastSuccess } from '../../../../utility/ToastFunction'

function ThumbImageData({ ...data }: ProductDetailData) {


    const { t } = useTranslation();
    const Schema = Yup.object().shape({
        alt: Yup.string().required(t("Validation.require")),
    });
    const { handleSubmit, control, formState: { errors }, getValues, setValue, reset } = useForm<any>({
        // resolver: yupResolver(Schema)
    });
    const [thumb, setThumb] = useState<any>({})
    const [isNew, setIsNew] = useState<boolean>(false)
    const [editThumb, results] = useProductEditPostMutation()
    const [addGallery, resultAdd] = useAddNewGalleryMutation()
    const [editgallery, resultEditGallery] = useEditGalleryMutation()
    const [emptyFile, setEmptyFile] = useState(false);
    const [altImage, setAltImage] = useState<string>("")

    const OnFinishEditImg: SubmitHandler<{ thumb: File, alt: string }> = (param) => {
        if (isNew && param.alt !=="") {
            addGallery({ id: data.id, image: param.thumb, alt: altImage })
        }
        if (param.alt == "") {
            ToastError("مقدار Alt الزامی است.")
        } else {
            if (param?.thumb !== undefined) {
                editgallery({ id: thumb?.id, image: param.thumb, alt: altImage })
                setEmptyFile(true)
            } else {
                editgallery({ id: thumb?.id, alt: altImage })
                // api without thumb
            }
        }

    }

    // تصویر اصلی

    const handleAlt = async () => {
        const fingThumb = await data.gallery.find(item => item.show === "thumb")

        await setThumb(fingThumb)
        await setAltImage(fingThumb ? fingThumb?.alt : "")
        if (fingThumb !== undefined) {
            setIsNew(false)
        } else {
            setIsNew(true)
        }
        setValue('alt', fingThumb ? fingThumb?.alt : "")
    }

    useEffect(() => {
        handleAlt()
    }, [])


    useEffect(() => {
        if (resultEditGallery.isSuccess) {
            ToastSuccess(t("Common.successResponse"))
        }
        if (resultEditGallery.isError) {
            ToastError(t("Common.errorResponse"))
        }
    }, [resultEditGallery])
    useEffect(()=>{
        if(resultAdd?.data?.success == true){
            ToastSuccess(t("Common.successResponse"))
        }else{
            ToastError(t("Common.errorResponse"))
        }
    },[resultAdd])


    // useEffect(() => {
    //     console.log("thumb is : ", thumb)
    //     if(thumb?.id){
    //         setIsNew(true)
    //     }else{
    //         setIsNew(false)
    //     }
    // }, [thumb])

    useEffect(() => {
        console.log("is new : ", isNew)
    }, [isNew])


    return (
        <>
            <div className='FormModal'>
                {data !== undefined && <div className='flex justify-center '>
                    <Gallery data={data.gallery} typeShow={["thumb"]} />
                </div>}
                <form onSubmit={handleSubmit((values: any) => OnFinishEditImg(values))}    >

                    <div className='w-full h-52'>
                        <FileInput
                            emptyFile={emptyFile}
                            control={control}
                            errors={errors}
                            getValues={() => { }}
                            changeValue={(file: File) => console.log("thumb", file)}
                            name="thumb"
                            accept='image/png,image/jpeg,image/jpg'
                            title={t("Txt.chooseImg")} />

                    </div>
                    <div className="w-full">
                        <InputTextWithoutForm classContainer='mb-6' value={altImage} getValue={(e: any) => {
                            setValue("alt", e)
                            setAltImage(e)
                        }} label={t("Common.alt")} placeholder={t("Common.alt")} />
                    </div>

                    <div className='mb-4 w-full flex justify-center'>
                        <div className='w-[50%]'>
                            <ButtonBrand type='submit' click={() => { }} loading={results.isLoading}>
                                <b>{t("Common.saveButton")}</b>
                            </ButtonBrand>
                        </div>
                    </div>
                </form>
            </div>


        </>
    )
}

export default ThumbImageData