import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { ChildrenProps } from '../../../interface/Element';
import useRtlDetermine from '../hook/RtlDetermine';

interface DialogProps extends ChildrenProps {
  Open: boolean,
  handleClickCloseFunc?: Function,
  handleCloseFun: Function

}
export default function DialogComponent({
  Open,
  handleClickCloseFunc,
  handleCloseFun,
  children
}: DialogProps) {
  //const [open, setOpen] = React.useState(Open);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const dir = useRtlDetermine()

  const handleClose = () => {
    //setOpen(false);
    handleCloseFun()

  };

  return (
    <Dialog

      style={{ minWidth: "60%" }}
      // maxWidth="md"
      fullScreen={fullScreen}
      open={Open}
      dir={dir}
      onClose={handleClose}
    aria-labelledby="responsive-dialog-title"
    >
      {children}
    </Dialog>
  );
}
