import { ID } from '../../../../interface/EntityCommon';
import { useUserDetailQuery } from "../../../../data/services/User";

const AddressesList=({id}:ID)=>{

    const { data } = useUserDetailQuery({ id: id })

    return(
       <div className='flexCol gap-8 text-lg'>
        {data?.data.user_addresses?.map(address=>(
            <div className="w-full shadow-lg flex flex-col p-8 rounded-md">
                <p>{`آدرس : ${address.address}`}</p>
                <p>{`عنوان : ${address.title}`}</p>
                <p>{`تلفن : ${address.phone}`}</p>
            </div>
        ))}
       </div>
    )
}
export default AddressesList