import { ProductDetailData, ProductInventory } from '../../../../interface/EntityProduct';
import { useEffect, useState } from 'react';

import AddInventory from './action/add';
import ButtonOuterBrandIcon from '../../../shared/button/ButtonOuterBrandIcon';
import EditIcon from '../../../shared/Icon/Edit';
import EditInventory from './action/edit';
import GrayLine from '../../../shared/Html/GrayLine';
import RemoveDialog from '../../../shared/dialog/RemoveDialog';
import TitleValue from '../../../shared/Html/TitleValue';
import TrashIcon from '../../../shared/Icon/Trash';
import { useProductRemoveInventoryMutation } from '../../../../data/services/Product';
import { useTranslation } from 'next-i18next';

export default function Inventory({ ...data }: ProductDetailData) {
    const { t } = useTranslation()
    const [ModalDeleteShow, setModalDeleteShow] = useState(false)
    const [removeItem, setRemoveItem] = useState<ProductInventory | null>(null);
    const [RemoveInventory, results] = useProductRemoveInventoryMutation()
    const [editItem, setEditItem] = useState<ProductInventory>({ id: 0, amount: "", product_id: 0, color: "", size: "" });
    const [mode, setMode] = useState<"add" | "edit">('add');

    const RemoveIcon = () => {
        if (removeItem !== null)
            RemoveInventory({ id: removeItem.id })


    }

    useEffect(() => {
        if (results.isSuccess) {
            setModalDeleteShow(false);
            setRemoveItem(null);

        }

    }, [results])
    return (
        <>
            <div className='FormModal'>

                <div className='border p-6 my-4 rounded-[--radius]'>
                    {mode === "add" && <AddInventory {...data} />}
                    {mode === "edit" && <EditInventory data={editItem} cancel={setMode} />}
                </div>

                <div className='flexRow gap-4 flex-wrap my-6 p-4 '>
                    {data.inventory.map((item) => <>
                        <div className="w-[250px] shadow-xl flexCol gap-4 rounded-[--radius] p-4">
                            <TitleValue title={t("Txt.size")} value={item.size} key={item.id} />
                            <GrayLine />
                            <div style={{ "color": item.color }}>
                                <TitleValue title={t("Txt.color")} value={item.color} key={item.id + "co"} showColor={true} />
                            </div>
                            <GrayLine />
                            <TitleValue title={t("Txt.amount")} value={item.amount} key={item.id + "m"} />
                            <GrayLine />
                            <div className="flexRow gap-2">
                                <ButtonOuterBrandIcon click={() => {setMode("add"); setEditItem(item); setMode("edit") }}>
                                    <EditIcon fill='var(--secondary)' />
                                </ButtonOuterBrandIcon>
                                <ButtonOuterBrandIcon click={() => { setRemoveItem(item); setModalDeleteShow(true) }}>
                                    <TrashIcon fill='var(--error)' />
                                </ButtonOuterBrandIcon>
                            </div>

                        </div>
                    </>)}

                </div>
            </div>


            {ModalDeleteShow && <RemoveDialog
                open={ModalDeleteShow}
                DeleteFunction={() => RemoveIcon()}
                CloseModal={() => setModalDeleteShow(false)}
                cancelTitle={t("Common.cancel")}
                deleteAgreeTitle={""}
                deleteTitle={t("Common.delete")}
                loadingConfirmButton={results.isLoading}
                title={t("Txt.removeItem") + removeItem?.size}
            />}
        </>
    )
}
