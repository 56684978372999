import { useEffect, useState } from 'react'
import * as Yup from "yup"
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import FormItem from '../FormItem';
import { useCategoryAddMutation } from '../../../../../data/services/Category';
import { ToastError, ToastSuccess } from '../../../../../utility/ToastFunction';
import ButtonBrand from '../../../../shared/button/ButtonBrand';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../data/store';

export default function AddCategory() {

    const { t } = useTranslation();
    const [parent, setParent] = useState(null)
    const [CategoryAdd, results] = useCategoryAddMutation()
    const Type = useSelector((state: RootState) => state.CategoryForm.type)
    
    const Schema = Yup.object().shape({
        name: Yup.string().required(t("Validation.require")),
        alt: Yup.string().required(t("Validation.require")),
        // title_seo: Yup.string().required(t("Validation.require")),
        // description_seo: Yup.string().required(t("Validation.require")),
        description: Yup.string(),
    });

    const { handleSubmit, control, formState: { errors }, getValues, setValue, reset } = useForm<any>({
        resolver: yupResolver(Schema)
    });

    const OnFinish: SubmitHandler<any> = (data) => {
        if (data?.name !== undefined) {
            let params:any = data
            if (parent !== null)
                params = { ...params, ...{ parent } }
            if(params?.img===undefined)
                delete(params['img'])
            CategoryAdd({...params,type: Type});
        }
    }


    useEffect(() => {
        if (results.isSuccess) {
            ToastSuccess(t("Common.successResponse"))
            reset({
                name:"",
                description:"",
            })
        }
        if (results.isError) {
            ToastError(t("Common.errorResponse"))
        }
    }, [results])

    return (
            <section className="p-4">
                <form onSubmit={handleSubmit((values: any) => OnFinish(values))}    >
                    <FormItem control={control} errors={errors} getValues={getValues} setValue={setValue} />
                    <div className='w-full flex justify-center mt-10'>
                        <div className="w-1/3">
                            <ButtonBrand type='submit' loading={results.isLoading} key={"buttonForm"} click={OnFinish} >
                                <b>{t("Common.saveButton")}</b>
                            </ButtonBrand>
                        </div>
                    </div>
                </form>
            </section>
    )
}
