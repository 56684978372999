import { OrderDataType, OrderDetailData, OrderListApi, OrderListFilterApi, OrderStatusDataApi, OrderUpdatePropsApi } from "../../interface/EntityOrder";

import { changeToQueryParams, ChangeToQueryString } from "../../utility/Function";
import { ID } from "../../interface/EntityCommon";
import { ORDER_PATH, ORDER_PATH_DELETE, ORDER_STATUS_PATH } from "../../config/ApiConf";
import { baseQueryWithReAuth } from "../ApiCal";
import { createApi } from '@reduxjs/toolkit/query/react'

export const OrderApi = createApi({
    reducerPath: 'OrderApi',
    baseQuery: baseQueryWithReAuth,

    tagTypes: ["Order"],

    endpoints: (builder) => ({
        Order: builder.query<OrderListApi, OrderListFilterApi>({
            query: (body) => ({
                // url: ORDER_PATH+ChangeToQueryString(body),
                url: ORDER_PATH+changeToQueryParams(body),
                method: 'GET',

            })
            ,
            providesTags: ['Order'],
        }),

        OrderDetail: builder.query<OrderDetailData, ID>({
            query: (body) => ({
                url: ORDER_PATH+'/'+body.id,
                method: 'GET',

            })
            ,
            providesTags: ['Order'],
        }),

        OrderAllStatus: builder.query< OrderStatusDataApi,void>({
            query: () => ({
                url: ORDER_STATUS_PATH ,
                method: 'GET',

            }),
       
        }),


        OrderChangeStatus: builder.mutation<{success:boolean,message:string}, OrderUpdatePropsApi>({
            query: ({ id }) => ({
                url: ORDER_PATH + "/" + id,
                method: 'PUT',

            }),
            invalidatesTags: ['Order'],
        }),

       deleteOrder: builder.mutation<{success:boolean,message:string}, OrderUpdatePropsApi>({
        query: ({ id }) => ({
            url: ORDER_PATH_DELETE + "/" + id,
            method: 'DELETE',

        }),
        invalidatesTags: ['Order'],
    }),



    }),



})

export const {
    useOrderQuery,
    useOrderDetailQuery,
    useOrderChangeStatusMutation,
    useOrderAllStatusQuery,
    useDeleteOrderMutation
} = OrderApi


