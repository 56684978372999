import { FC } from "react"

interface IProps {
    title: string,
    value: string
}

const InfoFactor: FC<IProps> = ({ title, value }) => {
    return (
        <div className="flex justify-center items-center gap-2 text-nowrap">
            <span className="text-[#263238] text-sm font-bold">{`${title} :`}</span> <span className="text-[#504B4B] font-normal text-sm">{value}</span>
        </div>
    )
}
export default InfoFactor