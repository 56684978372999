import { Flip, toast } from 'react-toastify'

let conf = {
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    rtl:true,
    // theme: "colored",
    transition: Flip,
}


export const ToastError = (txt :string) => {
    toast.error(txt, { ...conf, ...{ position: "top-center" } })
}
export const ToastSuccess = (txt :string) => {
    toast.success(txt, { ...conf, ...{ position: "top-center" } })
}
export const ToastInfo = (txt :string) => {
    toast.info(txt, { ...conf, ...{ position: "top-center" } })
}


export function ErrorApi( result:any){
    if (result.status === 'rejected') {
        ToastError(result.error?.data?.message??("Validation.rejected"))
    
      }
     
}