import type {
    BaseQueryFn,
    FetchArgs,
    FetchBaseQueryError,
} from '@reduxjs/toolkit/query'


import { ToastError } from '../utility/ToastFunction';
import { UrlS } from '../config/Urls';
import { baseUrl } from './../config/ApiConf';
import { fetchBaseQuery } from '@reduxjs/toolkit/query'
import { getCookie } from '../utility/cookieFunctions';



const baseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
        headers.set("Accept", "application/json")
        // headers.set("Authorization", `Bearer ${GetItemLocal("userA")}`)
        headers.set("Authorization", `Bearer ${getCookie("token")}`)
        return headers;
    },
})
export const baseQueryWithReAuth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions: any) => {
    let result = await baseQuery(args, api, extraOptions);

    if (result.error && result.error.status === 401) {
        window.location.href = UrlS.login.main


    }

    if (result.error) {
        let error: any = result.error;
        ToastError(error?.data?.message ?? "error")
    }

    return result
}
