import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "next-i18next";
import { SubmitHandler, useForm } from "react-hook-form";
import * as Yup from "yup"
import { useEditSEOProductMutation } from "../../../../data/services/Product";
import ButtonBrand from "../../../shared/button/ButtonBrand";
import {  ResSEO, SEO } from "../../../../interface/EntityProduct";
import FormSEO from "./FormSEO";
import { FC, useEffect } from "react";
import { ToastError, ToastSuccess } from "../../../../utility/ToastFunction";
import { groupBy } from "../../../../utility/Function";


interface IProps {
    seo: SEO[],
    product_id: number
}

const EditSeoProdoct: FC<IProps> = ({ seo, product_id }) => {

    const { t } = useTranslation();
    const [editSEO, results] = useEditSEOProductMutation()
    const Schema = Yup.object().shape({
        title_seo: Yup.string().required((t("Validation.require"))),
        // redirect: Yup.string(),
        description_seo: Yup.string().required((t("Validation.require"))),
        canonical: Yup.string(),
        index: Yup.string().required((t("Validation.require"))).min(2, "لطفا مقداری انتخاب کنید."),

    });

    const { handleSubmit, control, formState: { errors }, getValues, setValue, reset, watch } = useForm<any>({
        resolver: yupResolver(Schema)
    });

    const OnFinish: SubmitHandler<any> = (data) => {

        if (data) {
            let bodyFormData: FormData = new FormData()
            const keys = Object.keys(data)
            keys.map((item, index) => {
                bodyFormData.append(`seo[${index}][key]`, item)
                bodyFormData.append(`seo[${index}][value]`, data[item])
            })
            editSEO({
                product_id,
                list: bodyFormData
            })
        }
    }
    useEffect(() => {
        const res: ResSEO = groupBy(seo, ({ key }) => key)
        if (seo) {
            reset({
                title_seo: res["title_seo"] ? res["title_seo"][0].value : "",
                description_seo: res["description_seo"] ? res["description_seo"][0].value : "",
                canonical: res["canonical"] ? res["canonical"][0].value : "",
                redirect: res["redirect"] ? res["redirect"][0].value : "",
                index: res["index"] ? res["index"][0].value : ""
            })
        } else {
            reset()
            reset({
                title_seo: "",
                description_seo: "",
                canonical: "", index: "",
                redirect:""
            })
        }
    }, [seo])

    useEffect(() => {
        if (results.isSuccess) {
            ToastSuccess(t("Common.successResponse"))
            // refreshPage()
        }
        if (results.isError) {
            ToastError(t("Common.errorResponse"))
        }
    }, [results])


    return (
        <div className="FormModal">
            <form onSubmit={handleSubmit((values: any) => OnFinish(values))}    >
                {seo && <FormSEO control={control} errors={errors} getValues={getValues} setValue={setValue} watch={watch} />}
                <div className='w-full flex justify-center mt-10'>
                    <div className="w-full sm:w-1/3">
                        <ButtonBrand type='submit'
                            loading={results.isLoading}
                            key={"buttonForm"}
                            click={OnFinish} >
                            <b>{t("Common.saveButton")}</b>
                        </ButtonBrand>
                    </div>
                </div>
            </form>
        </div>
    )
}
export default EditSeoProdoct