import { FormProps } from '../../../interface/Element'
import React from 'react'
import SelectInput from '../../shared/form/select/SelectInput'
import { useTranslation } from 'next-i18next'

function SelectTypeShow({ control, name = 'show',errors }: FormProps) {
    const { t } = useTranslation()

    return (
        <>
            <SelectInput
                options={[
                    { label: "", value: "" },
                    { label: t("Txt.showBoth"), value: "both" },
                    { label: t("Txt.showSingle"), value: "single" },
                    { label: t("Txt.showWholesaler"), value: "wholesaler" },

                ]}
                errors={errors}
                changeF={() => { }}
                control={control}
                keyValue={name}
                label={t("Txt.typeShowRequest")}
                name={name}
            />
        </>
    )
}

export default SelectTypeShow