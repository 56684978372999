import * as Yup from "yup"

import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { ToastError, ToastSuccess } from "../../../../utility/ToastFunction"

import ButtonOrange from '../../../shared/button/ButtonBrand'

import ModalFull from '../../../shared/modal/ModalFull'
import { useProductAddMutation } from '../../../../data/services/Product'
import { yupResolver } from '@hookform/resolvers/yup'
import FormItem from "./FormItem"
import FileInput from "../../../shared/form/upload/FileInput"
import { useTranslation } from "next-i18next"

function AddProduct({ openModal, ChangeOpen }: { openModal: boolean, ChangeOpen: Function }) {

    const [OpenAdd, setOpenAdd] = useState(false);
    const [productAdd, results] = useProductAddMutation()
    const [loading, setLoading] = useState(results.isLoading)

    const Schema = Yup.object().shape({
        name: Yup.string().required(("اجباری است")),
        // slug: Yup.string().required(("اجباری است")),
        code: Yup.string().required(("اجباری است")),
        price: Yup.number().required(("اجباری است")),
        description: Yup.string().required(("اجباری است")),
        price_description: Yup.string().required(("اجباری است")),
        title_seo: Yup.string().required(("اجباری است")),
        description_seo: Yup.string().required(("اجباری است"))

    });

    const { handleSubmit, control, formState: { errors }, getValues, setValue, reset, watch } = useForm<any>({
        resolver: yupResolver(Schema)
    });

    const OnFinish: SubmitHandler<any> = (data) => {
        console.log(data)
        if (data?.name !== undefined) {
            setLoading(true)
            productAdd(data);
        }
    }

    useEffect(() => {
        setOpenAdd(openModal);
        reset();
    }, [openModal, reset])

    useEffect(() => {
        if (results.isSuccess) {
            setLoading(false);
            setOpenAdd(false);
            ChangeOpen(false);
            ToastSuccess('ثبت گردید')
        }
        if (results.isError) {
            // console.log("error is : ", results.error?.data)
            //@ts-ignore
            const keys = Object.keys(results.error?.data)
            //@ts-ignore
            ToastError(results.error?.data[keys[0]][0])
            setLoading(false);
        }
    }, [ChangeOpen, results])
    const { t } = useTranslation();


    return (
        <>

            <ModalFull ChangeOpen={(open: boolean) => { setOpenAdd(open); ChangeOpen(open); }} Open={OpenAdd} title='افزودن محصول جدید'>
                <div className="FormModal">
                    <form onSubmit={handleSubmit((values: any) => OnFinish(values))}    >
                        <FormItem control={control} errors={errors} getValues={getValues} setValue={setValue} watch={watch} />

                        <div className='w-full sm:w-[45%] '>
                            <FileInput
                                changeValue={(file: File) => console.log("thumb", file)}
                                getValues={getValues}
                                setValue={setValue}
                                control={control}
                                errors={errors}
                                name="thumb"
                                accept='image/png,image/jpeg,image/jpg'
                                title={t("Txt.chooseImg")} />
                        </div>
                        <div className='w-full flex justify-center mt-10'>
                            <div className="w-full sm:w-1/3">
                                <ButtonOrange type='submit' loading={loading} key={"buttonForm"} click={OnFinish} >
                                    <b>{t("Common.saveButton")}</b>
                                </ButtonOrange>
                            </div>
                        </div>
                    </form>


                </div>

            </ModalFull>
        </>
    )
}

export default AddProduct