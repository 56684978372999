import HeaderActivity from "../../components/activity/header"
import ListActivity from "../../components/activity/list"

export const ActivityPage = () => {
    return (
        <div className="flexCol gap-4 pageLayout">
            {/* <HeaderActivity /> */}
            <ListActivity/>
        </div>
    )
}
export default ActivityPage