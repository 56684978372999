import PageForm from './slice/page/PageForm'
import UserForm from './slice/user/UserForm'
import BlogForm from './slice/blog/BlogForm'
import FaqForm from './slice/faq/FaqForm'
import TransactionForm from './slice/transaction/TransactionForm'
import BannerForm from './slice/banner/BannerForm'
import SliderForm from './slice/slider/SliderForm'
import { AboutApi } from './services/About'
import { AdminApi } from './services/Admin'
import AdminForm from './slice/admin/AdminForm'
import AuthData from './slice/auth/Auth'
import { CategoryApi } from './services/Category'
import CategoryForm from './slice/category/CategoryForm'
import { OrderApi } from './services/Order'
import OrderForm from './slice/order/OrderForm'
import OrderListData from './slice/order/OrderList'
import { PackageTypeApi } from './services/PackageType'
import { PermissionApi } from './services/Permission'
import { ProductApi } from './services/Product'
import { RoleApi } from './services/Role'
import RoleForm from './slice/role/RoleForm'
import Sidebar from './slice/sidebar/Sidebar'
import { RolePermissionApi } from './services/RolePermission'
import { SiteInfoApi } from './services/SiteInfo'
import { SupplierApi } from './services/Supplier'
import { SupportApi } from './services/Support'
import SupportForm from './slice/support/SupportForm'
import { TermApi } from './services/Term'
import { UserApi } from './services/User'
import { configureStore } from '@reduxjs/toolkit'
import { loginApi } from './services/Auth'
import { SliderApi } from './services/Slider'
import { BannerApi } from './services/banner'
import { BlogApi } from './services/Blog'
import { TransactionApi } from './services/Transaction'
import { FaqApi } from './services/Faq'
import { PageApi } from './services/Page'
import { SectionApi } from './services/Section'
import { ProfileApi } from './services/Profile'
import { DiscountApi } from './services/Discount'
import DiscountForm from './slice/discount/DiscountForm'
import ProductForm from './slice/product/ProductForm'
import { ActivityApi } from './services/Activity'

export const store = configureStore({
  reducer: {
    AuthData,
    AdminForm,
    OrderListData,
    CategoryForm,
    RoleForm,
    FaqForm,
    DiscountForm,
    SliderForm,
    SupportForm,
    OrderForm,
    BannerForm,
    BlogForm,
    UserForm,
    PageForm,
    Sidebar,
    ProductForm,
    TransactionForm,
    

    [TransactionApi.reducerPath]: TransactionApi.reducer,
    [SectionApi.reducerPath]: SectionApi.reducer,
    [PageApi.reducerPath]: PageApi.reducer,
    [FaqApi.reducerPath]: FaqApi.reducer,
    [RolePermissionApi.reducerPath]: RolePermissionApi.reducer,
    [TermApi.reducerPath]: TermApi.reducer,
    [SiteInfoApi.reducerPath]: SiteInfoApi.reducer,
    [BannerApi.reducerPath]: BannerApi.reducer,
    [AdminApi.reducerPath]: AdminApi.reducer,
    [SliderApi.reducerPath]: SliderApi.reducer,
    [AboutApi.reducerPath]: AboutApi.reducer,
    [OrderApi.reducerPath]: OrderApi.reducer,
    [RoleApi.reducerPath]: RoleApi.reducer,
    [SupplierApi.reducerPath]: SupplierApi.reducer,
    [SupportApi.reducerPath]: SupportApi.reducer,
    [UserApi.reducerPath]: UserApi.reducer,
    [CategoryApi.reducerPath]: CategoryApi.reducer,
    [ProductApi.reducerPath]: ProductApi.reducer,
    [PackageTypeApi.reducerPath]: PackageTypeApi.reducer,
    [loginApi.reducerPath]: loginApi.reducer,
    [PermissionApi.reducerPath]: PermissionApi.reducer,
    [BlogApi.reducerPath]: BlogApi.reducer,
    [DiscountApi.reducerPath]: DiscountApi.reducer,
    [ActivityApi.reducerPath]: ActivityApi.reducer,
    [ProfileApi.reducerPath]: ProfileApi.reducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat([
      PageApi.middleware,
      SectionApi.middleware,
      TransactionApi.middleware,
      RolePermissionApi.middleware,
      SliderApi.middleware,
      SiteInfoApi.middleware,
      BannerApi.middleware,
      FaqApi.middleware,
      TermApi.middleware,
      AboutApi.middleware,
      OrderApi.middleware,
      RoleApi.middleware,
      SupplierApi.middleware,
      UserApi.middleware,
      SupportApi.middleware,
      AdminApi.middleware,
      CategoryApi.middleware,
      ProductApi.middleware,
      PackageTypeApi.middleware,
      loginApi.middleware,
      PermissionApi.middleware,
      BlogApi.middleware,
      DiscountApi.middleware,
      ProfileApi.middleware,
      ActivityApi.middleware
    ])
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
