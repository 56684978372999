import React from 'react'
import { ChildrenProps } from '../../interface/Element'

export default function TabBar({ children }: ChildrenProps) {
    return (
        <div className="no-scrollbar w-full p-2 rounded-[--radius] border border-[--brand] flexRow justify-between gap-2 m-2 overflow-scroll">
            {children}
        </div>
    )
}
