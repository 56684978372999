import 'react-toastify/dist/ReactToastify.css';
import "./lang/config";
import './asset/ck.css';

import { BrowserRouter } from "react-router-dom";
import { Global } from '@emotion/react';
import Router from "./Router";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <>
      <Global
        styles={{
          '.MuiDrawer-root': { zIndex: '1 !important' },
          '.MuiDrawer-root > .MuiPaper-root': {
            // height: `${height} !important`,
            overflow: 'visible',
            // width: window.innerWidth > 750 ? '50%' : "100%",
            marginInline: 'auto',
            // display: `${Open ? "" : "none"}`,
          },
          '.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiDialog-paper ': {
            minWidth: "50%",
            overflowX: "hidden",
            marginInline: 'auto',
          },
          '.MuiTablePagination-actions': {
            direction: "ltr"
          },
          '.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.MuiTablePagination-toolbar.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar': {
            direction: "rtl",
            gap: "5px"
          }

        }}

      />
      <BrowserRouter >
        <Router />
      </BrowserRouter >
      <ToastContainer style={{ zIndex: 1300003 }} />

    </>


  );
}

export default App;
