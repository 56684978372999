import { useTranslation } from "next-i18next";
import { useEffect, useState } from "react";
import { useCategoryEditMutation, useEditSEOCategoryMutation, useGetCategoryQuery } from "../../../../../data/services/Category";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../data/store";
import * as Yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { ToastError, ToastSuccess } from "../../../../../utility/ToastFunction";
import { emptyCategoryFormData, emptyCategoryFormMode } from "../../../../../data/slice/category/CategoryForm";
import ButtonBrand from "../../../../shared/button/ButtonBrand";
import ButtonBrandOuter from "../../../../shared/button/ButtonBrandOuter";
import CkComponent from "../../../../shared/ck";
import InputTxt from "../../../../shared/form/input/InputTxt";
import InputTextarea from "../../../../shared/form/input/InputTextarea";
import { groupBy, scrollToTop } from "../../../../../utility/Function";
import { ResSEO } from "../../../../../interface/EntityProduct";


const CategorySEO = () => {

    const { t } = useTranslation();
    const [id, setId] = useState(0);
    const [editSEOCategory, results] = useEditSEOCategoryMutation()
    const { data: category, isLoading } = useGetCategoryQuery({ id }, { skip: !id })

    const dispatch = useDispatch();
    const Data = useSelector((state: RootState) => state.CategoryForm)

    const Schema = Yup.object().shape({
        title_seo: Yup.string().required(t("Validation.require")),
        description_seo: Yup.string().required(t("Validation.require")),
        canonical: Yup.string().required(t("Validation.require")),
    });

    const { handleSubmit, control, formState: { errors }, getValues, setValue, reset } = useForm<any>({
        resolver: yupResolver(Schema)
    });

    const handleEditSEO: SubmitHandler<any> = (data) => {
        console.log("data is : ", data)
        if (data) {
            let bodyFormData: FormData = new FormData()
            const keys = Object.keys(data)
            keys.map((item, index) => {
                bodyFormData.append(`seo[${index}][key]`, item)
                bodyFormData.append(`seo[${index}][value]`, data[item])
            })
            editSEOCategory({
                category_id: id,
                list: bodyFormData
            })
        }

    }

    useEffect(() => {
        if (Data.data) {
            reset({ title_seo: Data.data.title_seo, description_seo: Data.data.description_seo, })
            setId(Data.data.id)
        }
    }, [Data])


    useEffect(() => {
        if (results.isSuccess) {
            ToastSuccess(t("Common.successResponse"))
            Cancel()
        }
        if (results.isError) {
            ToastError(t("Common.errorResponse"))
        }
    }, [results])

    const Cancel = () => {
        reset();
        dispatch(emptyCategoryFormMode());
        dispatch(emptyCategoryFormData());
    }

    useEffect(() => {
        if (category?.success == true) {
        scrollToTop()

            if (category.data.category_seo.length > 0) {
                const res: ResSEO = groupBy(category?.data.category_seo, ({ key }) => key)
                reset({
                    title_seo: res["title_seo"] ? res["title_seo"][0].value : "",
                    description_seo: res["description_seo"] ? res["description_seo"][0].value : "",
                    canonical: res["canonical"] ? res["canonical"][0].value : "",
                })
            }
        } else {
            reset({
                title_seo: "",
                description_seo: "",
                canonical: "", index: ""
            })
        }
    }, [category])

    return (
        <section className="p-4">
           {!isLoading && <form onSubmit={handleSubmit((values: any) => handleEditSEO(values))} >
            <h2 className="text-center py-4 mb-8">فیلد های سئو برای دسته بندی {category?.data.name}</h2>
                <div className="w-full flex flex-col sm:flex-row gap-4 mb-4">
                    <InputTxt classContainer='w-full' control={control} errors={errors} keyValue='title_seo' label={t("Common.titleSeo")} name='title_seo' placeholder={t("Common.titleSeo")} />
                    <InputTxt classContainer='w-full' errors={errors} label={t("Common.canonicalLink")} name="canonical" control={control}
                        keyValue="canonical" />
                </div>
                <div className="w-full flex flex-col sm:flex-row gap-4 mb-4">
                    <InputTextarea errors={errors} label={t("Common.descriptionSeo")} name="description_seo" control={control}
                        keyValue="description_seo" />
                    <div className=""></div>
                </div>


                {/* buttons */}
                <div className='w-full flexRow gap-4 justify-center mt-10'>
                    <div className="w-1/3">
                        <ButtonBrand type='submit' loading={results.isLoading} key={"buttonForm"} click={handleEditSEO} >
                            <b>{t("Common.edit")}</b>
                        </ButtonBrand>
                    </div>
                    <div className="w-1/3">
                        <ButtonBrandOuter type='submit' loading={results.isLoading} key={"buttonForm"} click={() => Cancel()} >
                            <b>{t("Common.cancel")}</b>
                        </ButtonBrandOuter>
                    </div>
                </div>
                {/* end buttons */}
            </form>}

        </section>
    )
}
export default CategorySEO