
import  Cookie  from 'js-cookie';

export function SetToken (token: string, name?: 'userA' | 'userAR') {
  Cookie.set(name ?? 'userA', token, { secure: true, expires: 730 })
  return
}

export function GetToken (name?: 'userA' | 'userAR') {
  return Cookie.get(name ?? 'userA')
}

export function EmptyToken (name?: 'userA' | 'userAR') {
  return Cookie.remove(name ?? 'userA')
}
