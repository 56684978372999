import {
  CategoryAddPropsApi,
  CategoryDeletePropsApi,
  CategoryListApi,
  CategoryListParamQueryApi,
  CategoryUpdatePropsApi,
  ResultGetCategory
} from '../../interface/EntityCategory'

import { ChangeToFormData, ChangeToQueryString } from '../../utility/Function'
import { GetToken } from '../../utility/TokenFunction'
import { Category_PATH, Category_ADMIN_PATH } from '../../config/ApiConf'
import { baseQueryWithReAuth } from '../ApiCal'
import { createApi } from '@reduxjs/toolkit/query/react'

export const CategoryApi = createApi({
  reducerPath: 'categoryApi',
  baseQuery: baseQueryWithReAuth,

  tagTypes: ['Category'],

  endpoints: builder => ({
    Category: builder.query<CategoryListApi, CategoryListParamQueryApi>({
      query: body => ({
        url: Category_PATH + ChangeToQueryString(body),
        method: 'GET',
        headers: {
          Authorization: `Bearer ${GetToken()}`
        }
      }),
      providesTags: ['Category']
    }),
    getCategory: builder.query<ResultGetCategory, { id: number }>({
      query: body => ({
        url: `${Category_PATH}/${body.id}`,
        method: 'GET'
      }),
      providesTags: ['Category']
    }),

    CategoryAdd: builder.mutation<void, CategoryAddPropsApi>({
      query: body => ({
        url: Category_ADMIN_PATH,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${GetToken()}`
        },
        body: ChangeToFormData(body)
      }),
      invalidatesTags: ['Category']
    }),

    CategoryEdit: builder.mutation<void, CategoryUpdatePropsApi>({
      query: ({ id, ...rest }) => ({
        url: Category_ADMIN_PATH + `/${id}`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${GetToken()}`
        },
        body: ChangeToFormData({ ...rest, ...{ _method: 'PUT' } })
      }),
      invalidatesTags: ['Category']
    }),
    editSEOCategory: builder.mutation<void, { category_id: number; list: any }>(
      {
        query: body => ({
          url: `admin/categorySeo/${body.category_id}/insert`,
          method: 'POST',
          body: body.list
        }),
        invalidatesTags: ['Category']
      }
    ),

    CategoryDelete: builder.mutation<void, CategoryDeletePropsApi>({
      query: ({ id }) => ({
        url: Category_ADMIN_PATH + `/${id}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${GetToken()}`
        }
      }),
      invalidatesTags: ['Category']
    })
  })
})

export const {
  useCategoryQuery,
  useGetCategoryQuery,
  useCategoryAddMutation,
  useCategoryEditMutation,
  useCategoryDeleteMutation,
  useEditSEOCategoryMutation
} = CategoryApi
