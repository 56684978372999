import DialogComponent from '../drawer/Dialog'
import ButtonBrandOuter from '../button/ButtonBrandOuter'
import { ActivityDataType } from '../../../interface/EntityActivity'
import Dialog from '@mui/material/Dialog';

interface RemoveDialogProps {
    open: boolean,
    CloseModal: Function,
    activity?: ActivityDataType
}

function DetailActivityModal({ open, CloseModal, activity }: RemoveDialogProps) {
    return (
        <DialogComponent Open={open} handleCloseFun={CloseModal}>
            <div className="flexCol justify-center items-center gap-4 rounded-[--radius] mt-10 ">
                <p className="text-center">{activity?.description}</p>
                <ButtonBrandOuter className='!w-[200px] mb-10' click={() => { CloseModal() }}>
                    <b>متوجه شدم</b>
                </ButtonBrandOuter>
            </div>
        </DialogComponent>
    )
}

export default DetailActivityModal