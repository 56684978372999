import React from 'react'

const TitleValue = ({ title = '', value = '', className = '',showColor=false }) => {
  return (
    <div className={`flex flex-row w-full ${className}`}>
      <div className='basis-[35%] text-black'><b >{title} : </b></div>
      <div className='flex-grow truncate flex-row justify-evenly '>
        {value} {showColor && <div style={{background:value}} className='w-10 h-10 border-[1px] border-black rounded-full'>jsdl</div>}
      </div>
    </div>
  )
}

export default TitleValue