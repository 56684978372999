import { Dispatch, FC, HTMLProps, ReactNode, SetStateAction, useState } from 'react';
import { Controller } from 'react-hook-form';
import Select, { components, OptionProps } from 'react-select';
import { SelectSearchProps } from '../../select/interface';

import { baseUrl } from '../../../../../config/ApiConf';


interface IProps extends SelectSearchProps {
    setSearchKey: Dispatch<SetStateAction<string>>,
    value: [],
}
interface IOption {
    value: number;
    label: string;
    fullName: string,
    mobile: string,
    image: string,
    classInput?:HTMLProps<HTMLElement>["className"]
}


const MultiSelect: FC<IProps> = ({ value, setSearchKey,classContainer, control, rules, name, optionItem, isClearable = false, label, isLoading, DValue, change = () => { } }) => {

    const { Option } = components;


    const OptionComponent: FC<OptionProps<IOption>> = ({ data, ...props }) => {
        return (
            ///@ts-ignore
            <Option {...props}>
                {/* {menuItem} */}
                <div className='flex flex-row justify-start items-start gap-5 w-full'>
                    <div className='h-full flex justify-center items-center flex-grow-1 mt-2'>
                        {data.image ? <img className='w-12 h-12 bg-rose-400 rounded-full' src={baseUrl + data.image} alt="" /> :
                            <div className='h-11 w-11 gap-1 text-white ml-1 font-bold rounded-full flex justify-center items-center text-xl' style={{
                                background: '#5865f2',
                                fontFamily: 'monospace'
                            }}>
                                {data.fullName.substring(0, 2)}
                            </div>
                        }
                    </div>
                    <div>
                        <p className='mb-4'>{data.fullName}</p> 
                        <p>{data.mobile}</p>
                    </div>
                </div>
            </Option >
        )
    }

    return (
        <>
            <div className="InputLabelClass">
                <label htmlFor={name} className="LabelClass">{label}</label>
                <Controller
                    control={control}
                    rules={rules}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <Select
                            className="InputClass  !p-[.5rem]"
                            classNamePrefix="select"
                            isDisabled={false}
                            isLoading={isLoading}
                            isClearable={isClearable}
                            isRtl={true}
                            isSearchable={true}
                            name={name}
                            options={optionItem}
                            aria-label={name}
                            placeholder={label}
                            components={{
                                Option: OptionComponent
                            }}
                            onChange={(value) => {
                                change(value)
                                // onChange(value?.value)
                            }
                            }
                            onInputChange={(e) => e.length > 0 ? setSearchKey(e) : setSearchKey("")}
                            isMulti={true}
                            value={value}
                            defaultInputValue={optionItem.find(ele => ele.value === value)?.label}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 999999 }) }}

                        />
                    )}

                    name={name} />


            </div>
        </>
    );
};
export default MultiSelect