import { useEffect, useState } from 'react'
import { FormComponentProps } from '../../../../interface/Element'
import InputTxt from '../../../shared/form/input/InputTxt'
import { useTranslation } from 'next-i18next'
import NumberInput from '../../../shared/form/input/NumberInput'
import DateP from '../../../shared/form/input/DateP'
import { useUserQuery } from '../../../../data/services/User'
import MultiSelect from '../../../shared/form/input/multiSelectSearch/MultiSelect'
import CustomSelect from '../../../shared/form/input/CustomSelect'
import DividerWithLabel from '../../../shared/Html/DividerWithLabel'

export interface IUser {
    value: number,
    id: number,
    label: string,
    mobile?: string,
    image: string | undefined | any,
    fullName: string
}

export default function FormItem({ control, getValues, setValue, errors, watch }: FormComponentProps) {

    const [users, setUsers] = useState<IUser[]>([])
    const { t } = useTranslation()
    const [searchKey, setSearchKey] = useState<string>("")

    const { data, isLoading, refetch } = useUserQuery({ page: 1, search_user: searchKey })

    const handleChangeUserList = async () => {
        if (data?.success == true) {
            let userList: IUser[] = []
            data.data.data.map((user) => {
                const newUser: IUser = {
                    id: user.id,
                    value: user.id,
                    label: user.first_name ? user?.first_name + " " + user?.last_name : "",
                    fullName: user.first_name ? user?.first_name + " " + user?.last_name : "",
                    mobile: user.mobile,
                    image: user.images ? user.images.find(u => u.type == "profile")?.pathFile : ""
                }
                userList.push(newUser)
            })
            await setUsers(userList)
        }
    }

    useEffect(() => {
        if (data?.success === true) {
            handleChangeUserList()
        }
    }, [data])

    useEffect(() => {
        if (searchKey.length > 3) {
            refetch()
        }
    }, [searchKey])

    return (
        <div className='flexCol flex-wrap w-full gap-5'>
            <div className="inputContainersForms">
                <InputTxt classContainer='w-full'
                    control={control}
                    errors={errors}
                    keyValue='name'
                    label={t("Common.name")}
                    name='name'
                    placeholder={t("Common.name")} />

                <InputTxt classContainer='w-full'
                    control={control}
                    errors={errors}
                    keyValue='code'
                    label={t("Common.code")}
                    name='code'
                    placeholder={t("Common.code")} />
            </div>
            <div className="inputContainersForms">
                <CustomSelect classContainer='h-[49px]' control={control} keyValue='type' errors={errors} label={t("Common.type")} name='type' optionItem={[{ label: t("Common.general"), value: "general" }, { label: t("Common.users"), value: "users" }]} />
                <div className="w-full">
                    {watch("type") === "users" &&
                        <MultiSelect setSearchKey={setSearchKey} control={control} keyValue='users'
                            value={watch("users")}
                            change={(e: { value: "", key: number }[]) => setValue("users", e)}
                            label={t("Common.users")} name='users' optionItem={users} />
                    }
                </div>
            </div>
            <div className="inputContainersForms">
                <CustomSelect classContainer='h-[49px]' control={control} keyValue='reductionType' errors={errors} label={t("Common.reductionType")} name='reductionType' optionItem={[{ label: t("Common.percent"), value: "percent" }, { label: t("Common.amount"), value: "amount" }]} />
                <NumberInput classContainer=''
                    control={control} errors={errors} keyValue='amount' label={t("Common.amount")} name='amount' placeholder={t("Common.amount")} />
            </div>

            <div className="inputContainersForms">
                <CustomSelect classContainer='h-[49px] !p-1' control={control} keyValue='active' errors={errors} label={t("Common.status")} name='active' optionItem={[{ label: t("Common.deActive"), value: 0 }, { label: t("Common.active"), value: 1 }]} />
                <NumberInput
                    control={control} errors={errors} keyValue='allowed_number' label={t("Common.allowedNumber")} name='allowed_number' placeholder={t("Common.allowedNumber")} />
            </div>




            <DividerWithLabel label={t("Common.timing")} />
            <div className="inputContainersForms mt-4">
                <DateP errors={errors} keyValue='start_date' label={t("Common.start_date")} control={control} name='start_date' />
                <DateP errors={errors} keyValue='expire_date' label={t("Common.expire_date")} control={control} name='expire_date' />
            </div>
        </div>
    )
}
