import { useTranslation } from "next-i18next"
import { FormComponentProps } from "../../../../interface/Element"
import InputTxt from "../../../shared/form/input/InputTxt"
import InputTextarea from "../../../shared/form/input/InputTextarea"
import CustomSelect from "../../../shared/form/input/CustomSelect"

const FormSEO = ({ control, errors, getValues, setValue, watch }: FormComponentProps) => {

    const { t } = useTranslation()
    return (
        <div className="w-full p-2 flexRow flex-wrap gap-8">
            <div className='w-full sm:w-[45%] '>
                <InputTxt errors={errors} label={t("Common.titleSeo")} name="title_seo" control={control}
                    keyValue="title_seo" />
            </div>
            <div className='w-full sm:w-[45%] '>
                <InputTxt errors={errors} label={t("Common.canonicalLink")} name="canonical" control={control}
                    keyValue="canonical" />
            </div>
            <div className='w-full sm:w-[45%] '>
                <InputTxt errors={errors} placeholder="مثال : /products/گوشواره-طرح-طلا-بیضی-شکل/" label={`آدرس redirect بدون دامنه`} name="redirect" control={control}
                    keyValue="redirect" />
            </div>
            <div className='w-full sm:w-[45%]'>
                <CustomSelect control={control} keyValue='reductionType' errors={errors}  label={t("index")} name='index' optionItem={[{ label:"index,followd", value: "index,followd" }, { label: "noindex", value: "noindex" }]} />
            </div>
            <div className='w-full sm:w-[45%] '>
                <InputTextarea errors={errors} label={t("Common.descriptionSeo")} name="description_seo" control={control}
                    keyValue="description_seo" />
            </div>
           
        </div>
    )
}
export default FormSEO