import { Controller } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import useRtlDetermine from '../../hook/RtlDetermine';
import { InputTypeNumber } from './interface';

export default function NumberInput({
    label,
    placeholder,
    keyValue,
    name,
    control,
    rules,
    errors,
    classContainer,
    changeF
}: InputTypeNumber) {
    const dir = useRtlDetermine()



    return (
        <div className='w-full'>
            <div key={keyValue} dir={dir} className={`InputLabelClass ${classContainer}`}  >
                <label htmlFor="" className="LabelClass">{label}</label>
                <Controller
                    control={control}
                    rules={rules}
                    render={({ field: { onChange, onBlur, value } }) => (

                        <NumericFormat
                            thousandSeparator=","
                            dir={'ltr'}
                            value={value ?? ''}
                            placeholder={placeholder}
                            onValueChange={(values, sourceInfo) => {
                                // console.log(values, sourceInfo);
                                onChange(values.value)
                                if(changeF){
                                    changeF(value?.value)
                                }

                            }}
                            className='InputClass'

                        //{...register(name??"firstName")}  
                        />

                    )}

                    name={name} />



            </div>

            <div>
                <p className='text-red-400 font-bold text-sm m-2'>{errors[name]?.message}</p> 

            </div>
        </div>
    )
}
