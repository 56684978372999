import { useParams } from "react-router"
import { useProductDetailQuery, useProductEditMutation } from "../../data/services/Product";
import { FC, useEffect, useState } from "react";
import { ProductDetailData } from "../../interface/EntityProduct";
import { Loader } from './../../components/shared/Loader';
import CkComponent from "../../components/shared/ck";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import * as Yup from "yup"
import { useTranslation } from "next-i18next";
import ButtonBrand from "../../components/shared/button/ButtonBrand";
import { ToastError, ToastSuccess } from "../../utility/ToastFunction";

interface RouteParams {
    id: string
}

interface IProps{
    idProduct?:number
}

const ReviewPage:FC<IProps>=({idProduct})=> {
    const params = useParams()
    const { id } = params
    const { data: dataP, isLoading, isFetching } = useProductDetailQuery({ id: idProduct ? idProduct: parseInt(id ? id : "") });
    const [editProduct, results] = useProductEditMutation()
    const [product, setProduct] = useState<ProductDetailData>()
    const [text, setText] = useState<string>(product?.review ? product.review : "")
    const { t } = useTranslation()
    const Schema = Yup.object().shape({
        name: Yup.string().required((t("Validation.require"))),
        slug: Yup.string().required((t("Validation.require"))),
        code: Yup.string().required((t("Validation.require"))),
        price: Yup.number().required((t("Validation.require"))),
        description: Yup.string().required((t("Validation.require"))),
    });
   
    // document.getElementsByClassName('modal')[0]?.removeAttribute('tabindex')

    const { handleSubmit, control, formState: { errors }, getValues, setValue, reset, watch } = useForm<any>({
        resolver: yupResolver(Schema)
    });
    const OnFinish: SubmitHandler<any> = (data) => {
        if (data?.name !== undefined) {
            data = { ...data, review: text}
            delete (data['keys'])
            editProduct(data);
        }
    }


    useEffect(() => {
        if (dataP?.success == true) {
            setProduct(dataP.data)
            if(dataP.data.review !==null){
                setText(dataP.data.review)
            }else{
                setText("")
            }
            reset(dataP.data)
        }else{
            reset()
        }
    }, [dataP])

    useEffect(() => {
        if (results.isSuccess) {
            ToastSuccess(t("Common.successResponse"))
        }
        if (results.isError) {
            ToastError(t("Common.errorResponse"))
        }
    }, [results])

    useEffect(()=>{
        const elements =  document.getElementsByClassName("MuiDialog-container");
        elements[0]?.removeAttribute("tabindex")
    },[])
    return (
        <div className="flexCol gap-4 pageLayout">
            <h1 className="text-center">نقد و بررسی محصول {product?.name}</h1>
            {isLoading ?
                <div className='flexRow justify-center w-full'>
                    <Loader />
                </div> :
                <form onSubmit={handleSubmit((values: any) => OnFinish(values))}>
                    <CkComponent txt={dataP?.data.review ?? ""} changeFun={setText} />
                    <div className='w-full flex justify-center mt-10'>
                        <div className="w-full sm:w-1/3">
                            <ButtonBrand type='submit' loading={results.isLoading} key={"buttonForm"} click={OnFinish} >
                                <b>{t("Common.saveButton")}</b>
                            </ButtonBrand>
                        </div>
                    </div>
                </form>
            }
        </div>
    )
}

export default ReviewPage