import { useEffect, useState } from 'react';
import { Loader } from '../../shared/Loader';
import { useTransactionQuery } from '../../../data/services/Transaction';
import LabelTxt from '../../shared/Html/LabelTxt';
import ListDataTable from './../../shared/listData/index';
import FilterTransactions from '../form/actions/filter';

// i18n
import { useTranslation } from 'next-i18next';
import { showPersianDate } from '../../../utility/Function';

function ListData() {
  const { t } = useTranslation()
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState<any>({
    page,
    type: undefined,
    name: undefined,
    is_pay: undefined
  })
  const { data, isLoading, refetch } = useTransactionQuery(filter)


  const Headers = [
    { value: <b>#</b>, width: "w-[150px]" },
    { value: <b >{t("Txt.PaidPrice")}</b> },
    { value: <b >{t("Txt.PaidPerson")}</b> },
    { value: <b>{t("Txt.refId")}</b> },
    { value: <b>{t("Txt.created_at")}</b> },
    { value: <b>{t("Txt.type")}</b> },
    { value: <b>{t("Common.status")}</b> },
  ];
  const Rows = data?.data?.data.map((item) => {
    return {
      row: [
        { value: <div className='ms-1'>{item.id}</div>, width: "w-[150px]" },
        { value: <div className='ms-1'>{(+(item?.amount ?? 0)).toLocaleString() + " " + t("Common.unitPay")}</div> },
        { value: <div className='ms-1'>{item.user.first_name + " " + item.user.last_name}</div> },
        { value: <div className='ms-1'>{item.ref_id}</div> },
        { value: <div className='ms-1'>{showPersianDate(item.created_at)}</div> },
        { value: <div className='ms-1'>{item.type == "credit" ? t("Common.credit") : t("Common.purchase")}</div> },
        {
          value: <div className='ms-1'>
            {item.is_pay ? <LabelTxt className={"bg-[--success]"} txt={t("Txt.isPaid" + item.is_pay)} />
              : t("Txt.isPaid" + item.is_pay)}
          </div>
        },
      ]
    }
  })


  const handleChangePageNumber = async (pageNumber: number) => {
    await setPage(pageNumber)
    setFilter({ ...filter, page: pageNumber })
  }

  useEffect(() => {
    refetch()
  }, [page])

  return (
    <>
      <FilterTransactions
        page={page}
        filter={filter}
        setFilter={setFilter}
        getData={() => refetch()}
      />
      <div className='  py-4' >
        {isLoading && <div className='flexRow justify-center w-full'><Loader /></div>}
        {!isLoading &&
          <ListDataTable
            header={Headers}
            setPage={(e: number) => {
              handleChangePageNumber(e)
            }
            }
            handleChangePage={(params1: any, params2: number) => {
              let newPage = params2 + 1
              handleChangePageNumber(newPage)
            }
            }
            rows={Rows ?? []}
            currentPage={+(data?.data.current_page ?? 1) - 1}
            rowsPerPage={+(data?.data.per_page ?? 0)}
            total={+(data?.data.total ?? 0)}

            onRowsPerPageChange={(params1: any, params2: any) =>
              console.log(params1, params2)
            }
          />
        }
      </div>
    </>
  )
}

export default ListData