export const AppName = 'تیادم'

export const HeaderTitleSubString = 30

export const ImgUploadType =
  'image/png,image/jpeg,image/jpg,image/webp,image/gif'

export const colors = [
  { label: 'chooseOption', value: '' },
  { label: 'gold', value: '#FFF323' },
  { label: 'silver', value: '#AAAAAA' },
  { label: 'roseGold', value: '#B76E79' },
  { label: 'green', value: '#3c875e' },
  { label: 'pink', value: '#d1979b' },
  { label: 'black', value: '#090909' },
  { label: 'white', value: '#bfbbb8' },
  { label: 'orange', value: '#d74b1b' },
  { label: 'mixColor', value: '#bfbbb8' },
  { label: 'blue', value: '#a1d3e2' },
  { label: 'lilac', value: '#e1ccff' },
  { label: 'cream', value: '#edcd9a' },
  { label: "Champagne", value: "#A37B73FF" },
  { label: "purple", value: "#9932cc" },

]
