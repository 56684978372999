import { Controller } from 'react-hook-form'
import React from 'react'
import { InputType } from './interface'

const InputTxt = ({
  label,
  placeholder,
  control,
  rules,
  name,
  defaultValue,
  errors = {},
  changeF,classContainer
}: InputType) => {

  const HtmlElement = ({value,onChangeF}:{value:string,onChangeF:any}) => <>
    <div className={`InputLabelClass ${classContainer}`}>
      <label htmlFor="" className="LabelClass">{label}</label>
      <input
        type="text"
        value={value}
        name=""
        id=""
        autoComplete='off'
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          onChangeF(event.currentTarget.value) }}
        className="InputClass" placeholder={placeholder} />

    </div>
  </>

  return (
    <div className='w-full'>
      {control&&<Controller
        control={control}
        rules={rules}
        render={({ field: { onChange, onBlur, value } }) => (
          <HtmlElement onChangeF={onChange} value={value} />

        )}

        name={name} />}
        {!control && <HtmlElement value={defaultValue??""} onChangeF={changeF}/>}
      <div>
        <p className='text-red-400 font-bold text-sm m-2'>{errors[name]?.message}</p>
      </div>
    </div>
  )
}

export default InputTxt