

import {  changeToQueryParams } from "../../utility/Function";
import { ACTIVITY_PATH } from "../../config/ApiConf";
import { baseQueryWithReAuth } from "../ApiCal";
import { createApi } from '@reduxjs/toolkit/query/react'
import { ActivityListFilter, ActivityListListApi } from "../../interface/EntityActivity";

export const ActivityApi = createApi({
    reducerPath: 'ActivityApi',
    baseQuery: baseQueryWithReAuth,
    tagTypes: ["Activity"],
    endpoints: (builder) => ({
        getActivity: builder.query<ActivityListListApi, ActivityListFilter>({
            query: (body) => ({
                url: ACTIVITY_PATH+changeToQueryParams(body),
                method: 'GET',
            }),
            providesTags: ['Activity'],
        }),
    })
})

export const { useGetActivityQuery} = ActivityApi


