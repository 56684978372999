import { useNavigate } from 'react-router-dom'
import { ChildrenProps } from '../../interface/Element'
import useCheckAuth from '../../hook/CheckAuth'
import { useEffect } from 'react'
import { UrlS } from '../../config/Urls'
import { removeCookie } from '../../utility/cookieFunctions'
import { RemoveItemLocal } from '../../utility/FunctionStorage'

const LoginLayout = ({ children }: ChildrenProps) => {

  const navigate = useNavigate()
  const Auth = useCheckAuth()

  useEffect(() => {
    if (Auth && Auth !== undefined) {
      removeCookie("token")
      RemoveItemLocal("userA")
      navigate(UrlS.dashboard.main)
    }
  }, [Auth, navigate])

  return (
    <div className={`flex flex-col  w-full gap-y-4 h-svh centerLayout `} dir='rtl'>
      <div>

      </div>
      <div className={`p-2 rounded-tr-[2rem] rounded-tl-[2rem] mt-[-1.9rem] bg-[--light] h-full flex-grow flex flex-col items-end justify-center   gap-y-10  `}>
        {children}
      </div>
    </div>
  )
}

export default LoginLayout