import ButtonBrand from "../../../../shared/button/ButtonBrand"
import imgBrand from "../../../../../asset/img/logo.png"
import InfoFactor from "./InfoFactor"
import { FC, useRef } from "react"
import { showPersianDate } from "../../../../../utility/Function"
import { useSiteInfoDataQuery } from "../../../../../data/services/SiteInfo";
import { useReactToPrint } from "react-to-print";
import { useLocation, useParams } from "react-router-dom"
import { useTranslation } from "next-i18next"
import { colors } from "../../../../../config/App";

interface IProps {
    order: any,
    postPrice: number
}

const Factor = () => {
    const { data } = useSiteInfoDataQuery()
    const { id } = useParams()
    const { state } = useLocation();
    const { order, postPrice } = state
    const getSumCount = () => {
        let count = 0
        // eslint-disable-next-line array-callback-return
        order?.products.map((product: any) => {
            count = count + (+product?.pivot?.count)
        })
        return count
    }

    const getSumUnitPrice = () => {
        let count = 0
        // eslint-disable-next-line array-callback-return
        order?.products.map((product: any) => {
            count = count + (+product?.pivot?.unit_price)
        })
        return count
    }

    const getSumFinalPrice = () => {
        let count = 0
        // eslint-disable-next-line array-callback-return
        order?.products.map((product: any) => {
            count = count + (+product?.pivot?.final_price)
        })
        return count
    }
    const findColor = (codeColor: string) => {
        const findObject = colors.find(item => item?.value === codeColor)
        return findObject?.label ? t(`Common.${findObject.label}`) : ""
    }
    const contentRef = useRef<HTMLDivElement>(null);
    const { t } = useTranslation()
    const reactToPrintFn = useReactToPrint({ contentRef, bodyClass: "rtlForce", });

    return (
        <section className="w-full flex flex-col justify-center items-center" ref={contentRef}>
            {/* <div className="w-[1080px] flex flex-col justify-center items-center"> */}
            <div className="w-[1000px] flex flex-col justify-center items-center">
                {/* header */}
                <div className="w-full flex justify-between items-center py-8 print:hidden">
                    <img src={imgBrand} alt="" className="w-[70px] h-[70px]" />
                    <ButtonBrand className="!w-[150px]"
                        click={() => reactToPrintFn()}
                    >چاپ</ButtonBrand>
                </div>
                {/* header end */}
                <div className="w-full h-[1px] bg-[#343434] mb-[58px]" />
                <div className="flex w-full flex-col gap-8">
                    {/* seller and receiver info */}
                    <div className="w-full border border-[#676767] ">
                        <div className="w-full flex border-b border-[#676767]">
                            <div className="w-[56px] py-4 bg-[#E1E1E1] flex justify-center items-center"><p
                                className="[writing-mode:vertical-rl] font-bold text-base text-[#263238]">فروشنده</p>
                            </div>
                            <div
                                className="w-full flex justify-start items-center gap-10 pr-4 border-r border-[#676767]">
                                <InfoFactor title="فروشنده" value="تیادم" />
                                {data?.data && <InfoFactor title="شماره تماس" value={data?.data?.site_info?.phone} />}
                                {data?.data && <InfoFactor title="آدرس" value={data?.data?.site_info?.address} />}
                                {data?.data && <InfoFactor title="کد پستی" value={data?.data?.site_info?.phone} />}
                            </div>
                        </div>
                        <div className="w-full flex">
                            <div className="w-[56px] py-4 bg-[#E1E1E1] flex justify-center items-center"><p
                                className="[writing-mode:vertical-rl] font-bold text-base text-[#263238]">خریدار</p>
                            </div>
                            <div
                                className="w-full flex justify-start items-center gap-10 p-2 pr-4 border-r border-[#676767] flex-wrap gap-y-4">
                                <InfoFactor title="خریدار"
                                    value={order?.user?.first_name + ' ' + order?.user?.last_name} />
                                <InfoFactor title="موبایل" value={order?.user?.mobile} />
                                <InfoFactor title="آدرس" value={order?.address?.address} />
                                <InfoFactor title="تاریخ ثبت سفارش" value={showPersianDate(order?.created_at)} />
                                <InfoFactor title="کد پیگیری" value={order?.id} />
                                <InfoFactor title="هزینه ارسال" value={postPrice.toLocaleString()} />
                                <InfoFactor title="مبلغ پرداختی" value={(+order?.price).toLocaleString()} />
                            </div>
                        </div>
                    </div>
                    {/* seller and receiver info end */}

                    <table className="border border-[#676767] border-collapse">
                        <thead>
                            <tr className="border border-[#676767]">
                                <th className="header-table-factor">ردیف</th>
                                <th className="header-table-factor">شناسه کالا</th>
                                <th className="header-table-factor">شرح کالا</th>
                                <th className="header-table-factor">سایز</th>
                                <th className="header-table-factor">رنگ</th>
                                <th className="header-table-factor">تعداد</th>
                                <th className="header-table-factor">مبلغ واحد</th>
                                <th className="header-table-factor">مبلغ کل</th>
                                {/*<th className="header-table-factor">تخفیف</th>*/}
                            </tr>
                        </thead>
                        <tbody>
                            {order?.products.map((product: any, index: number) => (
                                <tr className="border border-[#676767] !min-h-[60px]">
                                    <td className="td-table">{index + 1}</td>
                                    <td className="td-table">{product?.id}</td>
                                    <td className="td-table">{product?.name}</td>
                                    <td className="td-table">{product?.pivot?.size}</td>
                                    <td className="td-table flex justify-center items-center">
                                    {findColor(product?.pivot?.color)}
                                    </td>
                                    <td className="td-table">{product?.pivot?.count}</td>
                                    <td className="td-table">{(+product?.pivot?.unit_price).toLocaleString()}</td>
                                    <td className="td-table">{(+product?.pivot?.final_price).toLocaleString()}</td>
                                    {/*<td className="td-table">مبلغ تخفیف</td>*/}
                                </tr>
                            ))}
                            <tr className="border border-[#676767] !min-h-[60px]">
                                <th className="border border-[#676767] h-[60px] text-center bg-[#C1C1C1] " scope="row" colSpan={5}>جمع کل</th>
                                <td className="border border-[#676767] h-[60px] text-center bg-[#C1C1C1]">{getSumCount()}</td>
                                <td className="border border-[#676767] h-[60px] text-center bg-[#C1C1C1]">{getSumUnitPrice().toLocaleString()}</td>
                                <td className="border border-[#676767] h-[60px] text-center bg-[#C1C1C1]">{getSumFinalPrice().toLocaleString()}</td>
                            </tr>
                            <tr className="border border-[#676767] !min-h-[60px]">
                                <th className="border border-[#676767] h-[60px] text-center bg-[#C1C1C1] " scope="row" colSpan={5}>جمع کل همراه هزینه ارسال</th>
                                <th className="border border-[#676767] h-[60px] text-center bg-[#C1C1C1] " scope="row" colSpan={3}>
                                    {(getSumFinalPrice() + postPrice).toLocaleString()} {t("Common.unitPay")}
                                </th>

                            </tr>
                        </tbody>
                    </table>

                    {/*قسمت امضا ها*/}
                    <div className="w-full flex border border-[#676767] h-[170px]">
                        <div className="w-1/2 h-full border-l border-[#676767]">
                            <div
                                className="w-[56px] py-4 bg-[#E1E1E1] flex justify-center items-center h-full border-l border-[#676767]">
                                <p
                                    className="[writing-mode:vertical-rl] font-bold text-base text-[#263238] ">مهر و
                                    امضا خریدار</p>
                            </div>
                        </div>
                        <div className="w-1/2 h-full ">
                            <div
                                className="w-[56px] py-4 bg-[#E1E1E1] flex justify-center items-center h-full border-l border-[#676767]">
                                <p
                                    className="[writing-mode:vertical-rl] font-bold text-base text-[#263238] ">مهر و
                                    امضا فروشنده</p>
                            </div>
                        </div>
                    </div>
                    {/*پایان امضا ها*/}
                </div>
            </div>
        </section>
    )
}
export default Factor