import * as Yup from "yup"
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from "next-i18next";
import { SubmitHandler, useForm } from 'react-hook-form';
import ButtonBrand from "../../../shared/button/ButtonBrand";
import NumberInput from "../../../shared/form/input/NumberInput";
import { useAddMinPriceMutation } from "../../../../data/services/User";
import { useEffect } from "react";
import { ToastError, ToastSuccess } from "../../../../utility/ToastFunction";


const OrderSettingWholesale = ({ user_id, minFirsOrderPrice }: { user_id: number, minFirsOrderPrice: string }) => {

    const { t } = useTranslation()
    const [addMinPrice, result] = useAddMinPriceMutation()

    const Schema = Yup.object().shape({
        min_first_order_price: Yup.number().required((t("Validation.require"))),
        user_id: Yup.number().required((t("Validation.require"))),

    });

    const { handleSubmit, control, formState: { errors }, getValues, setValue, reset, watch } = useForm<any>({
        resolver: yupResolver(Schema),
        defaultValues: {
            user_id,
            min_first_order_price: (+minFirsOrderPrice).toFixed(0)
        }
    });

    const OnFinish: SubmitHandler<any> = (data) => {
        addMinPrice(data)
    }

    useEffect(() => {
        if (result.data?.success == true) {
            ToastSuccess(t("Common.successAlarm"))
        }
        if(result.data?.success == false) {
            ToastError(t("Common.errorResponse"))
        }
    }, [result])

    return (
        <div className='flexCol gap-4 text-lg '>
            <form onSubmit={handleSubmit((values: any) => OnFinish(values))} className="mt-10">
                <div className="w-full flex justify-between gap-10">
                    <NumberInput classContainer='w-full'
                        control={control} errors={errors} keyValue='min_first_order_price' label={t("Txt.price")} name='min_first_order_price' placeholder="حداقل قیمت خرید برای عمده فروش" />
                    <div className="w-full" />

                </div>
                <div className="w-full flex justify-center items-center mt-10">
                    <ButtonBrand loading={result.isLoading} type="submit" className="!w-[200px]" click={() => { }}>{t("Common.save")}</ButtonBrand>
                </div>
            </form>
        </div>
    )
}
export default OrderSettingWholesale