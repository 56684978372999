import React, { useEffect, useState } from 'react'
import { ToastError, ToastSuccess } from '../../../../utility/ToastFunction';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup"
import { SubmitHandler, useForm } from 'react-hook-form';
import { useProductDetailQuery, useProductEditMutation } from '../../../../data/services/Product';
import { useTranslation } from 'next-i18next';
import FormItem from './FormItem';
import ButtonBrand from '../../../shared/button/ButtonBrand';
import { ProductDetailData } from '../../../../interface/EntityProduct';

function EditProduct({ ...data }: ProductDetailData) {
    const { t } = useTranslation();
    
    const [productEdit, results] = useProductEditMutation()
    const {data:dataP,isLoading,isFetching}=useProductDetailQuery({id:data.id});

    const Schema = Yup.object().shape({
        name: Yup.string().required((t("Validation.require"))),
        slug: Yup.string().required((t("Validation.require"))),
        code: Yup.string().required((t("Validation.require"))),
        price: Yup.number().required((t("Validation.require"))),
        description: Yup.string().required((t("Validation.require"))),
    });

    
    const { handleSubmit, control, formState: { errors }, getValues, setValue, reset ,watch} = useForm<any>({
        resolver: yupResolver(Schema)
    });
    
    useEffect(() => {
        if(dataP?.data){
            console.log("data : ",dataP.data)
            reset(dataP.data)
        }else{
            reset()
        }
        //reset(data)
    }, [dataP])


    const OnFinish: SubmitHandler<any> = (data) => {
     
        if (data?.name !== undefined) {

            delete (data['keys'])
            if(data.review == null){
                delete(data['review'])
            }
           
            productEdit(data);
        }
    }


    useEffect(() => {
        if (results.isSuccess) {
            ToastSuccess(t("Common.successResponse"))
            // refreshPage()
        }
        if (results.isError) {
            ToastError(t("Common.errorResponse"))
        }
    }, [results])
    return (
        <>

            <div className="FormModal">
                <form onSubmit={handleSubmit((values: any) => OnFinish(values))}    >

                   {dataP?.success&& <FormItem control={control} errors={errors} getValues={getValues} setValue={setValue} watch={watch} />}
                    <div className='w-full flex justify-center mt-10'>
                        <div className="w-full sm:w-1/3">
                            <ButtonBrand type='submit' loading={results.isLoading} key={"buttonForm"} click={OnFinish} >
                                <b>{t("Common.saveButton")}</b>
                            </ButtonBrand>
                        </div>
                    </div>
                </form>


            </div>

        </>
    )
}

export default EditProduct