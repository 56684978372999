import { FC, HTMLProps } from "react"

interface IProps{
    label:string,
    classNameContainer?:HTMLProps<HTMLElement>["className"],
    classNameLabel?:HTMLProps<HTMLElement>["className"],
}

const DividerWithLabel:FC<IProps>=({label,classNameContainer,classNameLabel})=>{
    return(
        <div className={`w-full relative z-0 flex justify-center items-center ${classNameContainer}`}>
            <span className={`px-4 z-10  bg-white ${classNameLabel}`}>{label}</span>
            <div className="w-full absolute  h-[1px] bg-gray-600 "/>
        </div>
    )
}
export default DividerWithLabel