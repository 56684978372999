import { useTranslation } from "next-i18next";
import ModalFull from "../../../../shared/modal/ModalFull"
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { setDiscountFormMode } from "../../../../../data/slice/discount/DiscountForm";
import { RootState } from "../../../../../data/store";
import { showPersianDate } from "../../../../../utility/Function";
import { useGetDiscountUsersQuery } from "../../../../../data/services/Discount";

const DetailsDiscount = () => {

    const { t } = useTranslation()
    const [OpenAdd, setOpenAdd] = useState(false);
    const { mode, data: discount } = useSelector((state: RootState) => state.DiscountForm);

    const { data, isLoading } = useGetDiscountUsersQuery({ id: discount ? discount?.id : 0 })

    const dispatch = useDispatch()

    return (
        <ModalFull ChangeOpen={
            (open: boolean) => {
                setOpenAdd(open);
                dispatch(setDiscountFormMode(open ? "detail" : "none"));
            }
        }
            Open={mode === "detail"} title={t("Common.detailDiscount")}>
            <div className="FormModal mt-10 shadow-md !h-auto flex flex-col gap-4">
                <div className="w-full flex items-center justify-between">
                    <p>نام تخفیف : {discount?.name}</p>
                    <p>کد تخفیف : {discount?.code}</p>
                </div>
                <div className="w-full flex items-center justify-between">
                    <p>تاریخ شروع : {discount && showPersianDate(discount.start_date)}</p>
                    <p>تاریخ انقضا : {discount && showPersianDate(discount.expire_date)}</p>
                </div>
                <div className="w-full flex items-center justify-between">
                    <p>وضعیت تخفیف : {discount?.active == 1 ? t("Common.active") : t("Common.deActive")}</p>
                    <p>کد تخفیف برای  : {t(`Common.${discount?.type}`)}</p>
                </div>
                <div className="w-full flex items-center justify-between">
                    <p>{t("Common.reductionType")} : {t(`Common.${discount?.reductionType}`)}</p>
                    <p>مقدار تخفیف : {`${discount?.amount} ${discount?.reductionType === "percent" ? "%" : ""}`}</p>
                </div>
            </div>
            <div className="mt-5 p-4 shadow-sm">
                <h3 className="text-center mb-4">لیست افرادی که از این تخفیف استفاده کرده اند:</h3>
                <div className="w-full flex flex-col justify-center items-center gap-2">
                    {data?.success == true && data?.data.data.length > 0 && data?.data.data.map(item => (
                        <div className="w-[50%] flex justify-between items-center p-4 rounded-md border-[1px] border-[--brand]">
                            <p>نام کاربر : {item.user.fullname}</p>
                            <p>نوع کاربر : {t(`Common.${item.user.type}`)}</p>
                            <p>شماره کاربر : {item.user.mobile}</p>
                            </div>
                    ))}
                </div>

            </div>
        </ModalFull>
    )
}
export default DetailsDiscount