import { useEffect, useState } from 'react'
import { useDiscountListQuery } from '../../../data/services/Discount'
import { Loader } from '../../shared/Loader'
import FormDiscount from '../form/index'
import LabelTxt from '../../shared/Html/LabelTxt'
import FilterDiscount from '../form/actions/filter'
import ListData from '../../shared/listData'

// icons
import SettingIcon from '../../shared/Icon/Setting'

// i18
import { useTranslation } from 'next-i18next'

// redux
import {
  setDiscountFormData,
  setDiscountFormMode
} from '../../../data/slice/discount/DiscountForm'
import { useDispatch } from 'react-redux'
import EyeIcon from '../../shared/Icon/Eye'
import { Tooltip } from '@mui/material'

const ListDiscount = () => {
  const [page, setPage] = useState(1)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [filter, setFilter] = useState<any>({
    page,
    type: undefined,
    name: undefined,
    active: undefined,
    reductionType: undefined
  })
  const { data, isLoading, refetch } = useDiscountListQuery(filter)

  const Headers = [
    { value: <b>#</b>, width: 'w-[150px]' },
    { value: <b>{t('Txt.name')}</b> },
    { value: <b>{t('Txt.type')}</b> },
    { value: <b>{t('Common.status')}</b> },
    { value: <b>{t('Txt.actions')}</b>, width: 'w-[380px]' }
  ]

  const Rows = data?.data?.data.map(item => {
    return {
      row: [
        { value: <div className='ms-1'>{item.id}</div>, width: 'w-[150px]' },

        { value: <div>{item.name}</div> },
        {
          value: (
            <div>
              {item.type === 'general'
                ? t('Common.general')
                : t('Common.users')}
            </div>
          )
        },
        {
          value: (
            <div>
              {item.active === 0 ? (
                <LabelTxt
                  className={'bg-[--error]'}
                  txt={t('Common.deActive')}
                />
              ) : (
                <LabelTxt
                  className={'bg-[--success]'}
                  txt={t('Common.active')}
                />
              )}
            </div>
          )
        },
        {
          value: (
            <div className='flexRow items-center'>
              <Tooltip title="ویرایش تخفیف">
                <div
                  className='cursor-pointer'
                  onClick={() => {
                    // handleOpenEditModal(item.id)
                    dispatch(setDiscountFormData(item))
                    dispatch(setDiscountFormMode('edit'))
                  }}
                >                 <SettingIcon fill='var(--secondary)' size='30px' />
                </div>
              </Tooltip>
              <Tooltip title="مشاهده جزئیات">
                <div className="cursor-pointer" onClick={() => {
                  dispatch(setDiscountFormData(item))
                  dispatch(setDiscountFormMode('detail'))
                }}><EyeIcon fill='var(--secondary)' size='30px' /></div>
              </Tooltip>
            </div>
          ),
          width: 'w-[380px]'
        }
      ]
    }
  })


  const handleChangePageNumber = async (pageNumber: number) => {
    await setPage(pageNumber)
    setFilter({ ...filter, page: pageNumber })
  }

  useEffect(() => {
    refetch()
  }, [page])

  return (
    <>
      <FilterDiscount
        page={page}
        filter={filter}
        setFilter={setFilter}
        getData={() => refetch()}
      />
      <div className='py-4'>
        {isLoading && (
          <div className='flexRow justify-center w-full'>
            <Loader />
          </div>
        )}
        {!isLoading && (
          <ListData
            header={Headers}
            setPage={(e: number) => handleChangePageNumber(e)}
            handleChangePage={(params1: any, params2: number) => {
              let newPage = params2 + 1
              handleChangePageNumber(newPage)
            }
            }
            rows={Rows ?? []}
            currentPage={+(data?.data.current_page ?? 1) - 1}
            rowsPerPage={+(data?.data.per_page ?? 0)}
            total={+(data?.data.total ?? 0)}
            onRowsPerPageChange={(params1: any, params2: any) =>
              console.log(params1, params2)
            }
          />
        )}
      </div>
      <FormDiscount />
    </>
  )
}
export default ListDiscount
