import React from 'react'
import { FormComponentProps } from '../../../../interface/Element'
import FileInput from '../../../shared/form/upload/FileInput'
import InputTxt from '../../../shared/form/input/InputTxt'
import { useTranslation } from 'next-i18next'
import { ImgUploadType } from '../../../../config/App'
import InputTextarea from '../../../shared/form/input/InputTextarea'
import CkComponent from '../../../shared/ck'

export default function FormItem({ control, getValues, setValue, errors }: FormComponentProps) {
    
    const { t } = useTranslation()

    return (
        <>
            <div className='flexRow flex-wrap w-full'>
                <div className='w-full'>
                    <div className="w-full flex flex-col sm:flex-row gap-4 mb-4 justify-between">
                        <InputTxt classContainer='w-full' control={control} errors={errors} keyValue='name' label={t("Common.title")} name='name' placeholder={t("Common.title")} />
                        <InputTxt classContainer='w-full' control={control} errors={errors} keyValue='alt' label={t("Common.alt")} name='alt' placeholder={t("Common.alt")} />
                    </div>
                    {/* <div className="w-full flex flex-col sm:flex-row gap-4 mb-4">
                        <InputTxt classContainer='w-full' control={control} errors={errors} keyValue='title_seo' label={t("Common.titleSeo")} name='title_seo' placeholder={t("Common.titleSeo")} />
                        <InputTxt classContainer='w-full' errors={errors} label={t("Common.canonicalLink")} name="canonical" control={control}
                            keyValue="canonical" />
                    </div> */}
                    {/* <div className="w-full flex flex-col sm:flex-row gap-4 mb-4">
                        <InputTextarea errors={errors} label={t("Common.descriptionSeo")} name="description_seo" control={control}
                            keyValue="description_seo" />
                        <div className="w-full"></div>
                    </div> */}
                    <FileInput
                        changeValue={(file: File) => console.log("thumb", file)}
                        getValues={getValues}
                        setValue={setValue}
                        control={control}
                        errors={errors}
                        name="img"
                        accept={ImgUploadType}
                        title={t("Txt.chooseImg")} />
                </div>
                <div className="w-full">
                    <CkComponent txt={getValues("description")} changeFun={(e: any) => setValue("description", e)} />
                </div>
                <br />


            </div>

        </ >


    )
}
