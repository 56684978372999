import { Controller } from 'react-hook-form';
import Select from 'react-select';
import { SelectSearchProps } from '../select/interface';




export default function CustomSelect({ isSearchable, classContainer,control, errors, rules, name, optionItem, isClearable = false, label, isLoading, DValue, change = () => { } }: SelectSearchProps) {

    return (
        <>
            <div className={`InputLabelClass w-full `}>
                <label htmlFor={name} className="LabelClass">{label}</label>
                <Controller
                    control={control}
                    rules={rules}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <Select
                            className={`InputClass  !p-[.5rem] ${classContainer}`}
                            classNamePrefix="select"
                            isDisabled={false}
                            isLoading={isLoading}
                            isClearable={isClearable}
                            isRtl={true}
                            isSearchable={true}
                            name={name}
                            options={optionItem}
                            aria-label={name}
                            placeholder={label}
                            onChange={(value) => {
                                change(value)
                                onChange(value?.value)
                            }
                            }
                            value={optionItem.find(ele => ele.value === value)}
                            defaultInputValue={optionItem.find(ele => ele.value === value)?.label}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 999999 }) }}
                        />
                    )}
                    name={name} />
                <p className='text-red-400 font-bold text-sm m-2'>{errors[name]?.message}</p>
            </div>
        </>
    );
};