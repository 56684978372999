import { useEffect, useState } from 'react'
import FormOrder from '../form'
import { Loader } from '../../shared/Loader'
import {
  useDeleteOrderMutation,
  useOrderChangeStatusMutation,
  useOrderQuery
} from '../../../data/services/Order'
import LabelTxt from '../../shared/Html/LabelTxt'
import ButtonBrandOuter from '../../shared/button/ButtonBrandOuter'
import { showPersianDate } from '../../../utility/Function'
import FilterOrders from '../form/actions/filter'
import ListDataTable from './../../shared/listData/index'

// icons
import EyeIcon from '../../shared/Icon/Eye'

// i18n
import { useTranslation } from 'next-i18next'

// redux
import {
  setOrderFormData,
  setOrderFormMode
} from '../../../data/slice/order/OrderForm'
import { RootState } from '../../../data/store'
import { useDispatch, useSelector } from 'react-redux'
import RemoveDialog from '../../shared/dialog/RemoveDialog'
import { ToastSuccess } from '../../../utility/ToastFunction'
import TrashIcon from '../../shared/Icon/Trash'

function ListData() {
  const { t } = useTranslation()
  const [page, setPage] = useState(1)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [orderId, setOrderId] = useState<number>(0)
  const [order, setOrder] = useState<any>()
  const [ModalDeleteShow, setModalDeleteShow] = useState(false);
  const [removeItem, setRemoveItem] = useState<any | null>(null);
  const [idOrder,setIdOrder]= useState(0)

  const [changeStatus, results] = useOrderChangeStatusMutation()
  const dispatch = useDispatch()
  const [filter, setFilter] = useState<any>({
    page: 1,
    order_status_id: '',
    name: ''
  })
  const { data, isLoading, refetch } = useOrderQuery(filter)
  const [deleteOreder, resultDelete] = useDeleteOrderMutation()


  const handleChangePageNumber = async (pageNumber: number) => {
    await setPage(pageNumber)
    setFilter({ ...filter, page: pageNumber })
  }

  useEffect(() => {
    refetch()
  }, [page])

  useEffect(() => {
    if (results.data?.success == true) {
      setShowModal(false)
      ToastSuccess(t("Common.successAlarm"))
      setOrderId(0)
    }
  }, [results])
  const handleDeleteOrder = async () => {
    deleteOreder({ id:removeItem?.id })
  }

  const Headers = [
    { value: <b>#</b>, width: 'w-[150px]' },
    { value: <b>{t('Txt.name')}</b> },
    { value: <b>{t('Txt.totalOrderPrice')}</b> },
    { value: <b>{t('Txt.description')}</b> },
    { value: <b>{t('Txt.statusTitle')}</b> },
    { value: <b>{t('Txt.changeStatusTo')}</b> },
    { value: <b>{t('Txt.actions')}</b>, width: 'w-[380px]' }
  ]

  const handleOpenDeleteModal=async(id:number)=>{
    await setIdOrder(id)
    setModalDeleteShow(true)
  }
  const Rows = data?.data?.data.map(item => {
    return {
      row: [
        { value: <div className='ms-1'>{item.id}</div>, width: 'w-[150px]' },
        {
          value: <div>{item.user.first_name + ' ' + item.user.last_name}</div>
        },
        { value: <div>{(+item.price).toLocaleString()}</div> },
        { value: <div>{showPersianDate(item.created_at)}</div> },
        {
          value: (
            <div>
              <LabelTxt txt={item?.activeStatus?.name} />
            </div>
          )
        },
        {
          value: (
            <div className='w-full p-2 h-full flex items-center'>
              {item.activeStatus?.nextStatus !== null &&
                item.activeStatus?.nextStatus?.admin_can_change === 1 && (
                  <ButtonBrandOuter
                    // click={() => changeStatus({ id: item.id })}
                    click={() => {
                      setOrder(item)
                      setOrderId(item.id)
                      setShowModal(true)
                      // handleChangeState(item.id )}
                    }}
                    key={'order_status_id_change' + item.id}
                    loading={results.isLoading}
                    className='p-1'
                  >
                    {item.activeStatus?.nextStatus.name}
                  </ButtonBrandOuter>
                )}
            </div>
          )
        },
        {
          value: (
            <div className='flexRow items-center'>
              <div
                className='cursor-pointer'
                onClick={() => {
                  dispatch(setOrderFormMode('view'))
                  dispatch(setOrderFormData(item))
                }}
              >
                <EyeIcon fill='var(--secondary)' size='30px' />
              </div>
              {item?.activeStatus?.allow_remove === 1 && <div className='cursor-pointer' onClick={() => { setModalDeleteShow(true); setRemoveItem(item) }}><TrashIcon fill="var(--error)" size='30px' /></div>}
            </div>
          ),
          width: 'w-[380px]'
        }
      ]
    }
  })

  useEffect(()=>{
    if(resultDelete.data?.success == true){
      ToastSuccess(t("Common.successAlarm"))
      setModalDeleteShow(false)
    }
  },[resultDelete])

  return (
    <>
      {ModalDeleteShow && <RemoveDialog
        open={ModalDeleteShow}
        DeleteFunction={() => handleDeleteOrder()}
        CloseModal={() => setModalDeleteShow(false)}
        cancelTitle={t("Common.cancel")}
        deleteAgreeTitle={""}
        deleteTitle={t("Common.delete")}
        loadingConfirmButton={results.isLoading}
        title={t("Txt.removeOrder")}

      />}
      <FilterOrders
        page={page}
        filter={filter}
        setFilter={setFilter}
        getData={() => {
          refetch()
        }}
      />

      <div className='  py-4'>
        {isLoading && (
          <div className='flexRow justify-center w-full'>
            <Loader />
          </div>
        )}
        {!isLoading && (
          <ListDataTable
            header={Headers}
            rows={Rows ?? []}
            currentPage={+(data?.data.current_page ?? 1) - 1}
            rowsPerPage={+(data?.data.per_page ?? 0)}
            total={+(data?.data.total ?? 0)}
            setPage={(e: number) => {
              handleChangePageNumber(e)
            }
            }
            handleChangePage={(params1: any, params2: number) => {
              let newPage = params2 + 1
              handleChangePageNumber(newPage)
            }
            }
            onRowsPerPageChange={(params1: any, params2: any) =>
              console.log(params1, params2)
            }
          />
        )}
      </div>
      <FormOrder />
      <RemoveDialog
        title={t('Common.Change State Order')}
        deleteTitle={t('Common.yes')}
        loadingConfirmButton={results.isLoading}
        CloseModal={() => setShowModal(false)}
        DeleteFunction={() => changeStatus({ id: order?.id })}
        cancelTitle={t('Common.no')}
        deleteAgreeTitle={t('Common.Do you want to Change state order to ', { state: order?.activeStatus?.nextStatus?.name })}
        open={showModal}
      />
    </>
  )
}

export default ListData
