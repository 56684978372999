import { ChangeToFormData, ChangeToFormDataGalleryVideoProducts, ChangeToFormDataProductSection, ChangeToQueryString } from "../../utility/Function";
import { EditGalleryPropsApi, KeyValue, ProductAddInventoryPropsApi, ProductAddPropsApi, ProductDetailApiProps, ProductEditGalleryPropsApi, ProductEditPropsApi, ProductEditVideoWithCoverPropsApi, ProductListApi, ProductListFilterApi, ProductRemoveGalleryPropsApi, ProductRemoveInventoryPropsApi, ProductRemoveKeyPropsApi, ProductSectionsListApi, ProductUpdateInventoryPropsApi, ProductUpdateKeyPropsApi,  resultEditCommentProduct,  resultEditGalleryApi } from "../../interface/EntityProduct";
import { PRODUCT_SECTION_PATH, ProductGallery, ProductGalleryAdd, ProductGalleryCoverVideo, ProductGalleryEdit, Product_ADMIN_INVENTORY_PATH, Product_ADMIN_KEY_PATH, Product_ADMIN_PATH, Product_PATH_COMMENT_LIST, Product_PATH_DETAIL, Product_PATH_LIST } from "../../config/ApiConf";

import { ChangeToFormDataGalleryProducts } from './../../utility/Function';
import { GetToken } from "../../utility/TokenFunction";
import { ID } from "../../interface/EntityCommon";
import { baseQueryWithReAuth } from "../ApiCal";
import { createApi } from '@reduxjs/toolkit/query/react'
import { CommentListApi, CommentUpdateProps, ICommentListApi } from "../../interface/EntityComment";

export const ProductApi = createApi({
    reducerPath: 'productApi',
    baseQuery: baseQueryWithReAuth,

    tagTypes: ["Product", "productSection"],

    endpoints: (builder) => ({
        Product: builder.query<ProductListApi, ProductListFilterApi>({
            query: (body) => ({
                url: Product_PATH_LIST + ChangeToQueryString(body),
                method: 'GET',

            })
            ,
            providesTags: ['Product'],
        }),

        ProductDetail: builder.query<ProductDetailApiProps, { id: number }>({
            query: (body) => ({
                url: Product_PATH_DETAIL + `/${body.id}`,
                method: 'GET',

            })
            ,
            providesTags: ['Product'],
        }),

        ProductComment: builder.query<CommentListApi, { id: number }>({
            query: (body) => ({
                url: Product_PATH_COMMENT_LIST + `/${body.id}`,
                method: 'GET',

            })
            ,
            providesTags: ['Product'],
        }),
        ProductCommentEdit: builder.mutation<void, CommentUpdateProps>({
            query: (body) => ({
                url: `${Product_PATH_COMMENT_LIST}/${body.id}?confirm=${body.confirm}`,
                method: 'PATCH',
                body
            }),
            invalidatesTags: ['Product'],
        }),
        ProductCommentEditUser: builder.mutation<resultEditCommentProduct, CommentUpdateProps>({
            query: (body) => ({
                url: `${Product_PATH_COMMENT_LIST}/${body.id}/commentProductConfirm`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['Product'],
        }),

        ProductSection: builder.query<ProductSectionsListApi, ID>({
            query: (body) => ({
                url: PRODUCT_SECTION_PATH + "/" + (body.id),
                method: 'GET',

            })
            ,
            providesTags: ['productSection'],
        }),

        ProductAdd: builder.mutation<void, ProductAddPropsApi>({
            query: (body) => ({
                url: Product_ADMIN_PATH,
                method: 'POST',

                body: ChangeToFormData(body)
            }),
            invalidatesTags: ['Product'],
        }),

        ProductEditPost: builder.mutation<void, ProductEditPropsApi>({
            query: ({ id, ...res }) => ({
                url: Product_ADMIN_PATH + '/' + id,
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${GetToken()}`,
                },
                body: ChangeToFormData({ ...res, ...{ "_method": "PUT" } })
            }),
            invalidatesTags: ['Product'],
        }),

        ProductEdit: builder.mutation<void, ProductEditPropsApi>({
            query: ({ id, ...res }) => ({
                url: Product_ADMIN_PATH + '/' + id,
                method: 'PUT',

                body: ({...res,id})
            }),
            invalidatesTags: ['Product'],
        }),

        ProductRemove: builder.mutation<void, ID>({
            query: ({ id }) => ({
                url: Product_ADMIN_PATH + '/' + id,
                method: 'DELETE',


            }),
            invalidatesTags: ['Product'],
        }),

        ProductEditGallery: builder.mutation<void, ProductEditGalleryPropsApi>({
            query: ({ id,alt, ...res }) => ({
                url: ProductGallery + '/' + id,
                method: 'POST',

                body: ChangeToFormDataGalleryProducts(res.images ?? [], res.show,alt)
            }),
            invalidatesTags: ['Product'],
        }),


        ProductEditGalleryVideo: builder.mutation<void, ProductEditVideoWithCoverPropsApi>({
            query: ({ id, ...res }) => ({
                url: ProductGalleryCoverVideo + '/' + id,
                method: 'POST',

                body: ChangeToFormData(res)
            }),
            invalidatesTags: ['Product'],
        }),

        ProductRemoveFromGallery: builder.mutation<void, ProductRemoveGalleryPropsApi>({
            query: ({ id }) => ({
                url: ProductGallery + '/' + id,
                method: 'DELETE',

            }),
            invalidatesTags: ['Product'],
        }),

        ProductAddInventory: builder.mutation<void, ProductAddInventoryPropsApi>({
            query: (body) => ({
                url: Product_ADMIN_INVENTORY_PATH,
                method: 'POST',

                body: ChangeToFormData(body)
            }),
            invalidatesTags: ['Product'],
        }),

        ProductUpdateInventory: builder.mutation<void, ProductUpdateInventoryPropsApi>({
            query: (body) => ({
                url: Product_ADMIN_INVENTORY_PATH + "/" + body.id,
                method: 'PUT',
                body
            }),
            invalidatesTags: ['Product'],
        }),

        ProductRemoveInventory: builder.mutation<void, ProductRemoveInventoryPropsApi>({
            query: (body) => ({
                url: Product_ADMIN_INVENTORY_PATH + "/" + body.id,
                method: 'DELETE',
            }),
            invalidatesTags: ['Product'],
        }),

        ProductAddKey: builder.mutation<void, KeyValue>({
            query: (body) => ({
                url: Product_ADMIN_KEY_PATH,
                method: 'POST',
                body: ChangeToFormData(body)
            }),
            invalidatesTags: ['Product'],
        }),
        editSEOProduct: builder.mutation<void, {product_id:number,list:any}>({
            query: (body) => ({
                url: `admin/productSeo/${body.product_id}/insert`,
                method: 'POST',
                body:body.list
            }),
            invalidatesTags: ['Product'],
        }),
        ProductUpdateKey: builder.mutation<void, ProductUpdateKeyPropsApi>({
            query: (body) => ({
                url: Product_ADMIN_KEY_PATH + "/" + body.id,
                method: 'PUT',
                body
            }),
            invalidatesTags: ['Product'],
        }),

        ProductRemoveKey: builder.mutation<void, ProductRemoveKeyPropsApi>({
            query: (body) => ({
                url: Product_ADMIN_KEY_PATH + "/" + body.id,
                method: 'DELETE',
            }),
            invalidatesTags: ['Product'],
        }),

        ProductSectionUpdate: builder.mutation<void, any>({
            query: (body) => ({
                url: PRODUCT_SECTION_PATH,
                method: 'POST',
                body: ChangeToFormDataProductSection(body)
            })
            ,
            invalidatesTags: ['productSection'],
        }),

        editGallery: builder.mutation<resultEditGalleryApi, EditGalleryPropsApi>({
            query: (body) => ({
                url: ProductGalleryEdit + '/' + body.id,
                method: 'PUT',

                body: ChangeToFormData(body)
            }),
            invalidatesTags: ['Product'],
        }),
        addNewGallery: builder.mutation<resultEditGalleryApi, EditGalleryPropsApi>({
            query: (body) => ({
                url: ProductGalleryAdd + '/' + body.id,
                method: 'POST',
                body: ChangeToFormData(body)
            }),
            invalidatesTags: ['Product'],
        }),

    }),



})

export const {
    useProductCommentEditUserMutation,
    useEditSEOProductMutation,
    useProductQuery,
    useProductDetailQuery,
    useProductCommentQuery,
    useProductSectionQuery,
    useProductAddMutation,
    useProductEditMutation,
    useProductRemoveMutation,
    useProductEditPostMutation,
    useProductEditGalleryMutation,
    useProductRemoveFromGalleryMutation,
    useProductAddInventoryMutation,
    useProductUpdateInventoryMutation,
    useProductRemoveInventoryMutation,
    useProductAddKeyMutation,
    useProductUpdateKeyMutation,
    useProductRemoveKeyMutation,
    useProductSectionUpdateMutation,
    useProductCommentEditMutation,
    useProductEditGalleryVideoMutation,
    useEditGalleryMutation,
    useAddNewGalleryMutation

} = ProductApi


