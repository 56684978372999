import { FormComponentProps } from '../../../../interface/Element'
import InputTxt from '../../../shared/form/input/InputTxt'
import InputTextarea from '../../../shared/form/input/InputTextarea'
import NumberInput from '../../../shared/form/input/NumberInput'
import PackageType from './PackageType'
import { useTranslation } from 'next-i18next'
import CategorySelect from '../../../category/select/CategorySelect'
import { useEffect, useState } from 'react'
import DividerWithLabel from '../../../shared/Html/DividerWithLabel'

export default function FormItem({ control, errors, getValues, setValue, watch }: FormComponentProps) {
    const { t } = useTranslation();
    const [pricePercent, setPricePercent] = useState(0)

    const SetPricePercentChange = () => {
        const price = watch("price")
        const percent: any = watch("price_single_percent")
        setPricePercent(((parseFloat(price) * parseInt(percent)) / 100) + parseFloat(price));
    }

    useEffect(() => {
        SetPricePercentChange()
    }, [])


    return (
        <>
            <div className="w-full p-2 flexRow flex-wrap gap-8">
                <div className="inputContainersForms">
                    <InputTxt classContainer='w-full' control={control} errors={errors} keyValue='name' label={t("Common.product_name")} name='name' placeholder={t("Common.product_name")} />
                    <InputTxt classContainer='w-full' control={control} errors={errors} keyValue='code' label={t("Common.product_code")} name='code' placeholder={t("Common.product_code")} />
                </div>
                <div className="inputContainersForms">
                    <CategorySelect control={control} errors={errors} name='category_id' />
                    <PackageType control={control} errors={errors} />
                </div>

                <div className='inputContainersForms'>
                    <NumberInput classContainer='w-full'
                        control={control} errors={errors} keyValue='pack_number' label={t("Txt.packageNumber")} name='pack_number' placeholder="" />
                    <NumberInput classContainer='w-full'
                        control={control} changeF={() => SetPricePercentChange()} errors={errors} keyValue='price' label={t("Txt.price")} name='price' placeholder="" />
                </div>
                <div className="inputContainersForms">
                    <NumberInput control={control} classContainer='w-full' changeF={() => SetPricePercentChange()} errors={errors} keyValue='price_single_percent' label={t("Txt.priceSinglePercent")} name='price_single_percent' placeholder="" />
                </div>

                <div className="w-full text-center">
                    {<p>{pricePercent.toLocaleString()}</p>}
                </div>

                <div className="inputContainersForms">
                    <InputTxt control={control} classContainer='w-full' errors={errors} keyValue='price_description' label={t("Common.price_description")} name='price_description' placeholder={t("Common.price_description")} />
                    <InputTxt errors={errors} classContainer='w-full' label={t("Common.titleSeo")} name="title_seo" control={control}
                        keyValue="title_seo" />
                </div>
                {/* <div className='w-full sm:w-[45%] '>
                    <InputTxt errors={errors} label={t("Common.canonicalLink")} name="canonical" control={control}
                        keyValue="canonical" />
                </div> */}
                <div className='inputContainersForms'>
                    <InputTextarea errors={errors} label={t("Common.descriptionSeo")} name="description_seo" control={control}
                        keyValue="description_seo" />
                    <InputTextarea control={control} errors={errors} keyValue='description' label={t("Txt.description")} name='description' placeholder={t("Txt.description")} />
                </div>
                <DividerWithLabel label={t("Common.image")} />
                <div className='w-full sm:w-[45%] '>
                    <InputTxt errors={errors} label={t("Common.alt")} name="alt" control={control}
                        keyValue="alt" />
                </div>



            </div>
        </>
    )
}
